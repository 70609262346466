import { Droppable } from 'agnostic-draggable';
import ld from 'lodash';

export default class SectionCardDroppable extends Droppable {
  data = null;
  constructor(element, options) {
    options = options || {};
    super(
      element,
      ld.merge(
        {
          accept: '.section-card-draggable',
          tolerance: 'pointer'
        },
        options.options || {}
      ),
      options.listeners || {}
    );
    this.data = options.data;
  }
}
