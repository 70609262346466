

























































































































































/* eslint-disable no-new */
import Confirm from '@/components/core/Confirm.vue';
import LessonListItem from '@/components/lessonlists/LessonListItem.vue';
import SectionActions from '@/components/plans/card/SectionActions.vue';
import SectionAttachments from '@/components/plans/card/SectionAttachments.vue';
import SectionGroup from '@/components/plans/card/SectionGroup.vue';
import SectionStandards from '@/components/plans/card/SectionStandards.vue';
import SectionStatuses from '@/components/plans/card/SectionStatuses.vue';
import SectionText from '@/components/plans/card/SectionText.vue';
import SectionTitle from '@/components/plans/card/SectionTitle.vue';
import LessonServices from '@/services/lesson-services';
import StickersServices from '@/services/stickers-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import SectionCardDraggable from '@/utils/section-card-draggable';
import SectionCardDroppable from '@/utils/section-card-droppable';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventStickerImage from '@/components/stickers/EventStickerImage.vue';
import { namespace } from 'vuex-class';
import { whiteValues } from '@/constants';
import { UserSession } from '@/common/user-session';
import LessonActions from '@/components/lessons/LessonActions.vue';
import EventActions from '@/components/events/EventActions.vue';

const settings = namespace('settings');
const lessons = namespace('lessons');
const plans = namespace('plans');
const events = namespace('events');

@Component({
  components: {
    SectionActions,
    SectionAttachments,
    SectionGroup,
    SectionStandards,
    SectionStatuses,
    SectionText,
    SectionTitle,
    LessonListItem,
    EventStickerImage,
    LessonActions,
    EventActions
  }
})
export default class SectionCard extends Vue {
  @Prop({ type: Object })
  dayObject: any;

  @Prop({ type: Boolean, default: true })
  showDetails?: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  enableDragDrop?: boolean;

  @Prop({ type: String, default: '', required: false })
  container?: string;

  @settings.Getter('getOtherSettings')
  otherSettings!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @Prop({ type: Object, default: () => { return {} }, required: false })
  cardStyle?: any;

  @settings.State('userMode')
  settingsUserMode!: string;

  @plans.Getter
  isEqual!: boolean;

  @plans.Getter
  isHorizontal!: boolean;

  @plans.State
  viewType!: string;

  @lessons.Mutation
  setEditLink!: (v:boolean) => void;

  @lessons.Action
  move!: (params?: any) => Promise<any>;

  @lessons.Action
  save!: (params?: any) => Promise<any>;

  @lessons.Action
  init!: (params?: any) => Promise<any>;

  @lessons.Action('link')
  linkLesson!: (params?: any) => Promise<any>;

  @lessons.Action
  swap!: (params?: any) => Promise<any>;

  @events.Action
  getEventNotificationsById!: (param?: any) => Promise<any>;

  @settings.Getter('getClassHeadOrder')
  classHeadOrder!: string;

  hover = false;
  actions = true;
  localShowDetails: any = false;

  draggable: any;
  droppable: any;

  $refs!: {
    sectionCard: any;
    confirm: Confirm;
  };

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get isPlansListView() {
    return this.localPage === 'plans' && this.viewType === 'L';
  }

  get hasCustomActions() {
    if (this.type === 'L') {
      return (this.localPage === 'plans' && this.viewType !== 'L' && this.viewType !== 'M')
    }
    return (this.type === 'E');
  }

  get lessonInput() {
    const excludes = LessonServices.excludedLessonActions(this.rawDayObject, this.userMode, this.localPage);
    return {
      mode: 'P',
      loadData: true,
      data: this.rawDayObject,
      exclude: this.rawDayObject.collaborateSubjectId > 0 ? excludes : [],
      lessonListsView: this.rawDayObject.type === 'E' ? undefined : this.isPlansListView
    }
  }

  get hasLessonActions() {
    return !this.isShowGoogle && this.isLesson && !(this.rawDayObject.collaborateSubjectId > 0 && this.rawDayObject.collaborateType !== 2);
  }

  get hasEventActions() {
    return !this.isReadonly && !this.isShowGoogle && (this.isEvent && (!this.$currentUser.isAdmin || this.localDayObject.schoolId));
  }

  getEventInput() {
    const excludes = LessonServices.excludedLessonActions(this.rawDayObject, this.userMode, this.localPage);
    if (this.$currentUser.isAdmin) {
      this.getEventNotificationsById({ eventId: this.rawDayObject.eventId, customEventId: this.rawDayObject.customEventId }).then((data: any) => {
        this.rawDayObject.notifications = data.notifications;
      }).catch(() => {
        this.rawDayObject.notifications = [];
      }).finally(() => {
        return {
          mode: 'P',
          loadData: true,
          data: this.rawDayObject,
          exclude: this.rawDayObject.collaborateSubjectId > 0 ? excludes : [],
          lessonListsView: this.rawDayObject.type === 'E' ? undefined : this.isPlansListView
        }
      });
    } else {
      return {
        mode: 'P',
        loadData: true,
        data: this.rawDayObject,
        exclude: this.rawDayObject.collaborateSubjectId > 0 ? excludes : [],
        lessonListsView: this.rawDayObject.type === 'E' ? undefined : this.isPlansListView
      }
    }
  }

  get sectionCardId() {
    return this.localDayObject.dayObjectId || CommonUtils.generateUUID();
  }

  get localObjectStyling() {
    const styling = this.localDayObject.style;
    if (this.haveStickers) {
      styling.display = 'flex';
    }
    return styling
  }

  get localColor () {
    return this.localDayObject.color || this.$vuetify.theme.currentTheme.primary;
  }

  get iconColor() {
    return this.localColor.toLowerCase() === '#ffffff' ? 'color: #787878;' : ''
  }

  get shiftStyle() {
    if (this.hover) {
      return '';
    } else if ((this.rawDayObject && this.rawDayObject.collaborateSubjectId > 0) || this.$currentUser.isAdmin || !this.enableDragDrop) {
      return 'position: relative; left: 21px';
    } else {
      return 'position: relative; left: 42px';
    }
  }

  iconColorOnHover(isHover: boolean) {
    if (isHover) {
      return this.iconColor;
    } else {
      return 'color: transparent';
    }
  }

  get localStyle() {
    return ld.merge({ backgroundColor: this.color, borderColor: this.color }, this.cardStyle || {});
  }

  get color() {
    return CommonUtils.getColor(this.localColor);
  }

  get isColorWhite() {
    try {
      const color = this.color.replace(/\s/g, '').toLowerCase();
      return whiteValues[color] === true;
    } catch (e) {
      return false;
    }
  }

  get type() {
    return this.localDayObject.type;
  }

  get isLesson() {
    return this.type === 'L';
  }

  get isEvent() {
    return this.type === 'E';
  }

  get isShowLock() {
    return CommonUtils.isTrue(this.localDayObject.showLock);
  }

  get isShowGoogle() {
    return CommonUtils.isTrue(this.localDayObject.showGoogle);
  }

  get isReadonly() {
    return CommonUtils.isTrue(this.localDayObject.rawDayObject.readonly);
  }

  get isShowComment() {
    if (this.localDayObject.rawDayObject.addendums) {
      const comment = this.localDayObject.rawDayObject.addendums.find((addendum: any) => addendum.type === 'COMMENT');
      if (comment) {
        return comment.typeId;
      }
    }
    return 0;
  }

  get userMode() {
    return this.localDayObject.userMode || this.settingsUserMode;
  }

  get localDayObject() {
    return (
      this.dayObject || {
        color: this.$vuetify.theme.currentTheme.primary,
        style: {
          fontSize: '10pt',
          fontStyle: 'normal',
          fontFamily: 'Arial, Helvetica, sans-serif',
          color: '#000000',
          fontWeight: 'normal',
          textAlign: 'left',
          textDecoration: 'none',
          sections: []
        }
      }
    );
  }

  get localDayObjectStyle() {
    return this.localDayObject.style;
  }

  get hasContent() {
    return LessonServices.hasContent(this.rawDayObject, true)
  }

  get showToggleButton() {
    return this.hasContent && this.hover;
  }

  get rawDayObject() {
    return this.localDayObject.rawDayObject || {};
  }

  get localTime() {
    const time = this.localDayObject.time;
    if (
      this.localDayObject.labelOrder === 'timeFirst' &&
      CommonUtils.hasNoText(time)
    ) {
      return '&nbsp;&nbsp;&nbsp;';
    }
    return time;
  }

  get localName() {
    const name = this.localDayObject.name;
    if (
      this.localDayObject.labelOrder !== 'timeFirst' &&
      CommonUtils.hasNoText(name)
    ) {
      return '&nbsp;&nbsp;&nbsp;';
    }
    return name;
  }

  get sections() {
    return this.localDayObject.sections;
  }

  get stickerURL() {
    return 'https://cdn.planbook.com/images/planbook-stickers/' + StickersServices.getStickerFileName(this.localStickerId) + '.png';
  }

  get localStickerId() {
    return this.localDayObject.rawDayObject.stickerId;
  }

  get haveStickers() {
    return this.localDayObject.type === 'E' && this.localStickerId > 0;
  }

  get stickerBackgroundStyle(): string {
    return this.localDayObject.rawDayObject.stickerBackgroundStyle || this.getValue('stickerBackgroundStyle', this.otherSettings.stickerBackgroundStyle || '1');
  }

  get stickerBackgroundColor(): string {
    return this.localDayObject.rawDayObject.stickerBackgroundColor || this.getValue('stickerBackgroundColor', this.otherSettings.stickerBackgroundColor || '#FFFFFF');
  }

  get stickerSize() {
    return this.localDayObject.rawDayObject.stickerSize || this.getValue('stickerSize', this.otherSettings.stickerSize || '40');
  }

  get dragging() {
    return this.$store.state.lessons.dragging;
  }

  set dragging(val: boolean) {
    this.$store.commit('lessons/setDragging', val);
  }

  get hasLessonTitle() {
    return CommonUtils.hasText(this.rawDayObject.lessonTitle);
  }

  get toggleShowDetailsColor() {
    if (this.localShowDetails || (this.type === 'L' && this.hasLessonTitle)) {
      return this.color;
    } else {
      return this.localDayObjectStyle.color;
    }
  }

  onMouseEnter() {
    if (!this.dragging) {
      this.hover = true;
    }
  }

  onMouseLeave() {
    if (!this.dragging) {
      this.hover = false;
    }
  }

  @Watch('showDetails')
  onShowDetailsChange() {
    this.localShowDetails = this.showDetails;
  }

  toggleShowDetails() {
    this.localShowDetails = !this.localShowDetails;
  }

  onClick(event: any, dayObject: any) {
    this.init({ loadData: true, data: this.rawDayObject });
    if (dayObject.rawDayObject.collaborateSubjectId > 0 && dayObject.rawDayObject.collaborateType === 1) {
      this.$refs.confirm.alert({
        title: this.$t('replicaClassLabel'),
        text: this.$t('collabEditRestricted'),
        option1ButtonAlternativeText: this.$t('okLabel')
      })
    } else {
      this.$eventBus.$emit('onSectionCardClicked', { event, dayObject });
    }
  }

  onBodyClick(event: any, dayObject: any) {
    const target = event.target;
    const rawDayObject = ld.cloneDeep(dayObject.rawDayObject);
    if (target.matches('a')) {
      this.openLink(target, dayObject);
    } else if (target.closest('a')) {
      this.openLink(target.closest('a'), dayObject);
    } else if (target.matches('.section-title[data-type="unit"]') ||
               target.closest('.section-title[data-type="unit"]') ||
               target.matches('.section-item-title[data-type="unit"]') ||
               target.closest('.section-item-title[data-type="unit"]')) {
      const unit = { ...rawDayObject.unit, isReadOnly: !this.$currentUser.isTeacher };
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: unit
      });
    } else if (target.matches('.section-title[data-type="linkedUnit"]') ||
               target.closest('.section-title[data-type="linkedUnit"]') ||
               target.matches('.section-item-title[data-type="linkedUnit"]') ||
               target.closest('.section-item-title[data-type="linkedUnit"]')) {
      const linkedUnit = { ...rawDayObject.linkedUnit, isReadOnly: !this.$currentUser.isTeacher };
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: linkedUnit
      });
    } else if (rawDayObject.type === 'GC') {
      window.open(rawDayObject.courseUrl, '_blank');
    }
  }

  openLink(a: HTMLAnchorElement, dayObject: any) {
    const url = a.href;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', a.text);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', dayObject.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', dayObject.collaborateSubjectId);
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  onContextMenu(event: any, dayObject: any) {
    if (dayObject.rawDayObject.collaborateSubjectId > 0 && dayObject.rawDayObject.collaborateType === 1) {
      return;
    }
    this.$eventBus.$emit('onSectionCardContextMenu', { event, dayObject });
  }

  openComment(commentId: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      modal: true,
      input: {
        action: 'view',
        objectId: commentId
      }
    });
  }

  async doCopy(from: any, to: any) {
    from.date = to.date;
    from.classId = to.classId;
    from.extraLesson = to.extraLesson;
    CommonUtils.showLoading();
    return this.init({ loadData: true, data: from }).then(() => {
      return this.save();
    }).finally(() => {
      CommonUtils.hideLoading();
    })
  }

  async doMove(from: any, to: any, copyIt: string) {
    const request = {
      className: to.className,
      classId: to.classId,
      date: to.date,
      extraLesson: to.extraLesson,
      overwriteIt: 'N',
      copyIt
    };
    CommonUtils.showLoading();
    return this.init({ loadData: true, data: from })
      .then(async () => {
        return this.move(request).then(async resp => {
          if (resp.data.returnMsg === 'lessonExists') {
            CommonUtils.hideLoading();
            return this.$refs.confirm
              .confirm({
                title: CommonUtils.isTrue(copyIt)
                  ? this.$t('copyLessonLabel')
                  : this.$t('moveLessonLabel'),
                text: this.$t('moveLessonMsg', {
                  className: request.className,
                  date: request.date
                }),
                option1ButtonAlternativeText: this.$t('shiftLessonLabel'),
                option2ButtonAlternativeText: this.$t('overwriteLessonLabel')
              })
              .then(async result => {
                if (result === 1) {
                  CommonUtils.showLoading();
                  request.overwriteIt = 'S';
                  return this.move(request);
                } else if (result === 2) {
                  CommonUtils.showLoading();
                  request.overwriteIt = 'O';
                  return this.move(request);
                } else {
                  return Promise.resolve();
                }
              });
          } else {
            return Promise.resolve();
          }
        });
      })
      .finally(() => {
        CommonUtils.hideLoading();
      });
  }

  async link(params?: any) {
    return this.linkLesson(params).then(async resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('lessonsLinked') as string);
      }
      return Promise.resolve(resp);
    });
  }

  async doLink(from: any, to: any) {
    const request: any = {
      targetClassName: to.className,
      targetExtraLesson: to.extraLesson,
      targetStartDate: to.date,
      targetSubjectId: to.classId,
      verifyLinkAction: true
    };
    CommonUtils.showLoading();
    return this.init({ loadData: true, data: from })
      .then(async () => {
        return this.link(request)
          .then(resp => {
            const data = resp.data;
            if (data.confirmLinkAction) {
              CommonUtils.hideLoading();
              return this.$refs.confirm.confirm({
                title: this.$t('linkLessonLabel'),
                text: this.$t('linkLessonMsg', {
                  className: request.targetClassName,
                  date: request.targetDate
                }),
                option1ButtonAlternativeText: this.$t('shiftLabel'),
                option2ButtonAlternativeText: this.$t('mergeLabel'),
                option3ButtonAlternativeText: this.$t('overwriteLabel')
              });
            } else {
              return Promise.resolve(0);
            }
          })
          .then(result => {
            if (result !== 0) {
              CommonUtils.showLoading();
              request.linkAction = result === 1 ? 0 : result === 2 ? 1 : 2;
              request.verifyLinkAction =
                result === 3 ? false : request.verifyLinkAction;
              return this.link(request);
            } else {
              return Promise.resolve({});
            }
          })
          .then((resp: any) => {
            const data = resp && resp.data ? resp.data : null;
            if (data && CommonUtils.isNotEmpty(data.deletedLessons)) {
              let deleteText = '';
              if (data.deletedLessons.length > 1) {
                deleteText += (
                  data.deletedLessons.length +
                  ' ' +
                  this.$t('lessonsLabel')
                ).toLowerCase();
              } else {
                deleteText += this.$t('aLessonLabel');
              }
              return this.$refs.confirm.confirm({
                title: this.$t('lessonLabel'),
                text: this.$t('confirmDeleteLesson3', { text: deleteText }),
                option1ButtonAlternativeText: this.$t('continueLabel')
              });
            } else {
              return Promise.resolve(0);
            }
          })
          .then(result => {
            if (result === 1) {
              CommonUtils.showLoading();
              request.verifyLinkAction = false;
              return this.link(request);
            } else {
              return Promise.resolve();
            }
          });
      })
      .finally(() => {
        CommonUtils.hideLoading();
      });
  }

  async doSwap(from: any, to: any) {
    const request = {
      subjectId: to.classId,
      date: to.date,
      extraLesson: to.extraLesson
    }
    CommonUtils.showLoading();
    return this.init({ loadData: true, data: from })
      .then(async () => {
        return this.swap(request).then(async (resp:any) => {
          if (resp.data.invalidDate) {
            return Promise.reject(this.$t('dateCannotBeOutsideMsg') as string);
          } else {
            return Promise.resolve(resp);
          }
        });
      })
      .finally(() => {
        CommonUtils.hideLoading();
      });
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  droppedCorrectDay(event: any, to: any) {
    try {
      if (this.viewType === 'M') {
        let element = event.data.sensorEvent.data.target;
        while (!element.classList.contains('v-calendar-weekly__day')) {
          element = element.parentElement
        }
        return (element.querySelector('.v-btn__content').innerHTML === to.date.substring(3, 5));
      } else {
        return true;
      }
    } catch {
      return true;
    }
  }

  beforeDestroy() {
    if (this.draggable) try { this.draggable.destroy(); } catch (e) {}
    if (this.droppable) try { this.droppable.destroy(); } catch (e) {}
    this.draggable = null;
    this.draggable = null;
  }

  mounted() {
    this.localShowDetails = this.showDetails;
    if (this.enableDragDrop && this.type === 'L') {
      this.draggable = new SectionCardDraggable(
        this.$refs.sectionCard.$el,
        {
          data: () => ld.cloneDeep(this.rawDayObject),
          options: {
            appendTo: this.container
          },
          listeners: {
            'drag:start': (event: any) => {
              const lesson = event.helper.draggableInstance.data();
              document.body.classList.add('prevent-select');
              event.helper.innerHTML = `
                <div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px;">
                    <div class="d-flex align-center justify-center">
                      <i class="fas fa-up-down-left-right"></i>
                    </div>
                    <div class="ml-2">
                        <div>${lesson.className}</div>
                        <div>${DateTimeUtils.formatToShow(lesson.date)}</div>
                    </div>
                </div>
              `;
              event.helper.style.height = '';
              event.helper.style.width = '';
              this.hover = false;
              this.dragging = true;
            },
            'drag:stop': () => {
              document.body.classList.remove('prevent-select');
              this.dragging = false;
            }
          }
        }
      );
      this.droppable = new SectionCardDroppable(
        this.$refs.sectionCard.$el,
        {
          data: () => ld.cloneDeep(this.rawDayObject),
          listeners: {
            'droppable:over': () => {
              this.hover = true;
              this.actions = false;
            },
            'droppable:out': () => {
              this.hover = false;
              this.actions = true;
            },
            'droppable:drop': (event: any) => {
              const from = event.draggable.data();
              const to = event.droppable.data();
              if (!ld.isEqual(from, to) && this.droppedCorrectDay(event, to)) {
                this.setEditLink(false);
                return this.$refs.confirm
                  .confirm({
                    title: this.$t('shareLessonLabel'),
                    text: this.$t('shareLessonMsg', {
                      from: from.className + ' ' + DateTimeUtils.formatToShow(from.date),
                      to: to.className + ' ' + DateTimeUtils.formatToShow(to.date)
                    }),
                    option1ButtonAlternativeText: this.$t('moveLabel'),
                    option2ButtonAlternativeText: this.$t('linkLabel'),
                    option3ButtonAlternativeText: this.$t('copyLabel'),
                    option4ButtonAlternativeText: this.$t('swapLabel')
                  })
                  .then(result => {
                    if (result === 1) {
                      return this.doMove(from, to, 'N');
                    } else if (result === 3) {
                      return this.doCopy(from, to);
                    } else if (result === 2) {
                      return this.doLink(from, to);
                    } else if (result === 4) {
                      return this.doSwap(from, to);
                    }
                    return Promise.resolve();
                  })
                  .finally(() => {
                    this.hover = false;
                    this.actions = true;
                    this.dragging = false;
                  });
              }
            }
          }
        }
      );
    }
  }
}
