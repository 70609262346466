
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
import DateTimeUtils from '@/utils/date-time-utils';
import { occurrences, timeTypes } from '@/constants';
import ld from 'lodash';
import { UserSession } from '@/common/user-session';
import EditorSection from '@/components/common/EditorSection.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';
import Confirm from '../core/Confirm.vue';
import { FormError } from '@/errors';
import StickersPicker from '@/components/stickers/StickersPicker.vue';
import StickersServices from '@/services/stickers-services';
import EventStickerImage from '@/components/stickers/EventStickerImage.vue';
import FileServices from '@/services/file-services';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';
import moment from 'moment';

const events = namespace('events');
const classes = namespace('classes');
const settings = namespace('settings');
const stickers = namespace('stickers');

@Component({
  components: {
    EditorSectionList,
    EditorSection,
    MyFilesPicker,
    StickersPicker,
    EventStickerImage,
    TextEditorFullScreenButton
  }
})
export default class EventEditorForm extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return { loadData: false, data: {} };
    }
  })
  input!: any;

  @Prop({ type: Boolean, required: false, default: true })
  isActive!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  localRefreshKey = CommonUtils.generateUUID();

  tabs = [
    {
      label: this.$t('descriptionLabel'),
      section: 'description'
    },
    {
      label: this.$t('attachmentsLabel'),
      section: 'attachments'
    },
    {
      label: this.$t('addDaysLabel'),
      section: 'addDays'
    },
    {
      label: this.$t('removeDaysLabel'),
      section: 'removeDays'
    }
  ];

  get filteredTabs() {
    return this.tabs.filter((t) => {
      if (this.isEditable || t.section === 'description') {
        if (t.section === 'removeDays') {
          return this.isEventDateRange;
        }
        return true;
      } else {
        if (t.section === 'attachments') {
          return this.hasAttachments;
        } else if (t.section === 'addDays') {
          return this.hasAddDays;
        } else if (t.section === 'removeDays') {
          return this.hasRemoveDays;
        }
      }
    })
  }

  dialogs: any = {
    myFiles: false
  };

  isEditing = false;
  showFormatEvent = false;
  refresh = CommonUtils.generateUUID();
  removeDaysHolder: Array<any> = [];
  addDaysHolder: Array<any> = [];
  errorMsg = '';
  isStickersPickerOpen = false;
  isSectionsHovered = false;
  isTextFieldsVisible = true;
  forceCannotEditEventMsg = false;

  @events.State
  origEvent!: any;

  @events.State
  deletedLessons!: any;

  @settings.State
  userMode!: string;

  @classes.Getter('getSpecialDayItems')
  specialDayItems!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter
  getPushNotificationsEnabled!: string;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  getCurrentYear!: any;

  @events.Action
  init!: (params?: any) => Promise<any>;

  @events.Action('updateNotifications')
  saveNotifications!: (params?: any) => Promise<any>;

  @events.Action('save')
  doSave!: (params?: any) => Promise<any>;

  @classes.Action
  loadSpecialDays!: (params?: any) => Promise<any>;

  @stickers.Getter('getSelectedStickers')
  selectedStickers!: any

  @stickers.State
  availableStickerData!: any;

  $refs!: {
    confirm: Confirm,
    filesPicker: MyFilesPicker
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isEditable() {
    if (this.$currentUser.isStudent) {
      return false;
    }
    if (CommonUtils.hasText(this.googleId)) {
      return false;
    }
    if (this.districtId && this.districtId > 0 && !this.$currentUser.isDistrictAdmin) {
      return false;
    }
    if (this.schoolId && this.schoolId > 0 && !this.$currentUser.isAdmin) {
      return false;
    }
    return true;
  }

  currentDateSubtractDays(days: number) {
    return DateTimeUtils.formatDate(DateTimeUtils.currentDateSubtractDays(days), 'YYYY-MM-DD');
  }

  get isFieldRestricted() {
    if (this.eventId > 0) {
      const eventDate = this.$store.state.events.origEvent.eventDate;
      const noSchool = this.$store.state.events.origEvent.noSchool;
      const noCycle = this.$store.state.events.origEvent.noCycle;
      if (CommonUtils.hasValue(eventDate) && CommonUtils.hasValue(noSchool) && CommonUtils.hasValue(noCycle) && !this.isSchoolYearNew) {
        if (DateTimeUtils.daysDiff(eventDate, DateTimeUtils.currentDateAddDays(0)) > 365) {
          return true;
        } else if ((noSchool || noCycle) && DateTimeUtils.daysDiff(eventDate, DateTimeUtils.currentDateAddDays(0)) > 30) {
          return true;
        }
      }
    }
    return false;
  }

  isAddOrRemoveDayRestricted(date: string) {
    if (this.isFieldRestricted) {
      const noSchool = this.$store.state.events.origEvent.noSchool;
      const noCycle = this.$store.state.events.origEvent.noCycle;
      if (CommonUtils.hasValue(date) && CommonUtils.hasValue(noSchool) && CommonUtils.hasValue(noCycle) && !this.isSchoolYearNew) {
        if (DateTimeUtils.daysDiff(date, DateTimeUtils.currentDateAddDays(0)) > 365) {
          return true;
        } else if ((noSchool || noCycle) && DateTimeUtils.daysDiff(date, DateTimeUtils.currentDateAddDays(0)) > 30) {
          return true;
        }
      }
    }
    return false;
  }

  get addDayAllowedDates() {
    return (val: string) => {
      val = moment(val, 'YYYY-MM-DD').format('MM/DD/YYYY');
      if (this.addDaysHolder.map(a => a.value).includes(val)) {
        return false;
      }
      if (this.repeats === 'weekly' && DateTimeUtils.isInWeeklySchedule(val, this.eventDate, this.endDate)) {
        return false;
      } else if (this.repeats === 'biweekly' && DateTimeUtils.isInBiweeklySchedule(val, this.eventDate, this.endDate)) {
        return false;
      } else if (this.repeats === 'daily' && DateTimeUtils.isBetween(val, this.eventDate, this.endDate)) {
        return false;
      }
      return true;
    }
  }

  get removeDayAllowedDates() {
    return (val: string) => {
      val = moment(val, 'YYYY-MM-DD').format('MM/DD/YYYY');
      if (this.removeDaysHolder.map(a => a.value).includes(val)) {
        return false;
      }
      if (this.repeats === 'weekly' && !DateTimeUtils.isInWeeklySchedule(val, this.eventDate, this.endDate)) {
        return false;
      } else if (this.repeats === 'biweekly' && !DateTimeUtils.isInBiweeklySchedule(val, this.eventDate, this.endDate)) {
        return false;
      } else if (this.repeats === 'daily' && !DateTimeUtils.isBetween(val, this.eventDate, this.endDate)) {
        return false;
      }
      return true;
    }
  }

  get minDate() {
    if (this.noSchool) {
      return this.currentDateSubtractDays(30);
    } else {
      return this.getCurrentYear?.firstDay ? DateTimeUtils.formatDate(DateTimeUtils.addDays(DateTimeUtils.toDate(this.getCurrentYear.firstDay), -30), 'YYYY-MM-DD') : '';
    }
  }

  get maxDate() {
    return this.getCurrentYear?.lastDay ? DateTimeUtils.formatDate(DateTimeUtils.addDays(DateTimeUtils.toDate(this.getCurrentYear.lastDay), 30), 'YYYY-MM-DD') : '';
  }

  get cannotEditEventMsg() {
    const eventDate = this.$store.state.events.origEvent.eventDate;
    const origNoSchool = this.$store.state.events.origEvent.noSchool;
    const origNoCycle = this.$store.state.events.origEvent.noCycle;
    if (DateTimeUtils.daysDiff(eventDate, DateTimeUtils.currentDateAddDays(0)) > 365) {
      return this.$t('cannotEditEventMsg');
    }
    if (this.isCycleSchedule) {
      if (this.eventId === 0) {
        return this.$t('cannotCreateNoCycleEventMsg');
      }
      if (!origNoCycle) {
        return this.hasAddDaysInThePast ? this.$t('cannotCreateNoCycleEventMsg') : this.$t('cannotChangeToNoCycleMsg');
      }
      return this.$t('cannotEditNoCycleEventMsg');
    }
    if (this.eventId === 0) {
      return this.$t('cannotCreateNoSchoolEventMsg');
    }
    if (!origNoSchool) {
      return this.hasAddDaysInThePast ? this.$t('cannotCreateNoSchoolEventMsg') : this.$t('cannotChangeToNoSchoolMsg');
    }
    return this.$t('cannotEditNoSchoolEventMsg');
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localEditorSectionConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get stickerBackgroundColor(): string {
    return this.$store.state.events.stickerBackgroundColor || '#FFFFFF';
  }

  get stickerBackgroundStyle(): string {
    return this.$store.state.events.stickerBackgroundStyle || '1';
  }

  get stickerURL() {
    if (this.$store.state.events.stickerId === 0 || !this.$store.state.events.stickerId) return '';
    return 'https://cdn.planbook.com/images/planbook-stickers/' + StickersServices.getStickerFileName(this.$store.state.events.stickerId) + '.png';
  }

  get stickers(): Array<any> {
    const that = this;
    const stickers = [];
    if (this.$store.state.events.stickerId > 0) {
      stickers.push(this.availableStickerData.stickers.find((sticker:any) => sticker.id === that.$store.state.events.stickerId));
    }
    this.$store.commit('stickers/setSelectedStickers', ld.cloneDeep(stickers));
    return ld.cloneDeep(stickers);
  }

  set stickers(val: Array<any>) {
    const stickers = ld.cloneDeep(val);
    this.$store.commit('events/setEventStickerId', stickers[0].id);
    if (this.input.openStickerPickerDefault) {
      this.eventTitle = ld.cloneDeep(stickers[0].shortValue);
    }
    if (this.input.openStickerPickerDefault) {
      this.$emit('eventStickerSaved');
    }
  }

  get localActiveTab() {
    return this.$store.state.events.activeTab;
  }

  set localActiveTab(tab: number) {
    this.$store.commit('events/setActiveTab', tab);
  }

  get formattedEventCurrentDate() {
    if (CommonUtils.hasText(this.eventCurrentDate)) {
      return DateTimeUtils.formatToShow(this.eventCurrentDate);
    }
    return '';
  }

  get initialized(): boolean {
    return this.$store.state.events.editorInitialized;
  }

  set initialized(val: boolean) {
    this.$store.commit('events/setEditorInitialized', val);
  }

  get eventCurrentDate(): string {
    return this.$store.state.events.eventCurrentDate;
  }

  set eventCurrentDate(val: string) {
    this.$store.commit('events/setEventCurrentDate', val);
  }

  get shiftLessons(): boolean {
    return CommonUtils.isTrue(this.$store.state.events.shiftLessons);
  }

  set shiftLessons(val: boolean) {
    this.$store.commit('events/setShiftLessons', CommonUtils.booleanToString(val));
  }

  get eventDate(): string {
    return this.$store.state.events.eventDate;
  }

  set eventDate(val: string) {
    this.$store.commit('events/setEventDate', val);
    this.addUpdatedField('eventDate');
  }

  get endDate(): string {
    return this.$store.state.events.endDate;
  }

  set endDate(val: string) {
    this.$store.commit('events/setEndDate', val);
    this.addUpdatedField('endDate');
  }

  get repeats(): string {
    return this.$store.state.events.repeats || 'daily';
  }

  set repeats(val: string) {
    this.$store.commit('events/setRepeats', val);
    this.addUpdatedField('repeats');
  }

  get eventText(): string {
    return this.$store.state.events.eventText;
  }

  set eventText(val: string) {
    this.$store.commit('events/setEventText', val);
    this.addUpdatedField('eventText');
  }

  get eventStartTime(): string {
    return this.$store.state.events.eventStartTime;
  }

  set eventStartTime(val: string) {
    this.$store.commit('events/setEventStartTime', val);
    this.addUpdatedField('eventStartTime');
  }

  get eventEndTime(): string {
    return this.$store.state.events.eventEndTime;
  }

  set eventEndTime(val: string) {
    this.$store.commit('events/setEventEndTime', val);
    this.addUpdatedField('eventEndTime');
  }

  get eventTitle(): string {
    return this.$store.state.events.eventTitle;
  }

  set eventTitle(val: string) {
    this.$store.commit('events/setEventTitle', val);
    this.addUpdatedField('eventTitle');
  }

  get customEventId(): number {
    return +this.$store.state.events.customEventId;
  }

  set customEventId(val: number) {
    this.$store.commit('events/setCustomEventId', val);
  }

  get updateCurrentEvent(): boolean {
    return this.$store.state.events.updateCurrentEvent;
  }

  set updateCurrentEvent(val: boolean) {
    this.$store.commit('events/setUpdateCurrentEvent', val);
  }

  get specialDayId(): number {
    return +this.$store.state.events.specialDayId;
  }

  set specialDayId(val: number) {
    this.$store.commit('events/setSpecialDayId', +val);
    this.addUpdatedField('specialDayId');
  }

  get schoolId(): number {
    return +this.$store.state.events.schoolId;
  }

  set schoolId(val: number) {
    this.$store.commit('events/setSchoolId', val);
  }

  get googleId(): string {
    return this.$store.state.events.googleId;
  }

  set googleId(val: string) {
    this.$store.commit('events/setGoogleId', val);
  }

  get districtId(): number {
    return +this.$store.state.events.districtId;
  }

  set districtId(val: number) {
    this.$store.commit('events/setDistrictId', val);
  }

  get noSchool(): boolean {
    return CommonUtils.isTrue(this.$store.state.events.noSchool);
  }

  set noSchool(val: boolean) {
    this.$store.commit('events/setNoSchool', CommonUtils.booleanToString(val));
    this.addUpdatedField('noSchool');
  }

  get eventNotificationsItems() {
    return (this.eventNotifications || []).map((n: any) => {
      const associatedInfo = timeTypes.find((i: any) => i.display === n.displayUnit);
      if (n.title) {
        return n;
      } else if (associatedInfo) {
        const displayTime = n.minutesPrior / associatedInfo.value;
        n.title = this.$t(associatedInfo.string, { x: displayTime });
        if (n.sendTime) {
          n.title = n.title + this.$t('notificationAtTime', { time: n.sendTime });
        }
      } else {
        n.title = this.$t('atEventLabel');
      }
      return n;
    })
  }

  get haveNotificationsItems() {
    return CommonUtils.isNotEmpty(this.eventNotificationsItems);
  }

  get eventNotifications() {
    return this.$store.state.events.notifications;
  }

  set eventNotifications(val: any) {
    this.$store.commit('events/setNotifications', val);
  }

  removeNotifcation(index: any) {
    this.eventNotifications.splice(index, 1);
    this.addUpdatedField('notifications');
  }

  updateNotifications() {
    if (CommonUtils.hasNoText(this.eventStartTime)) {
      this.eventNotifications = this.eventNotifications.filter((n: any) => n.sendTime && n.sendTime !== null);
    } else {
      this.eventNotifications = this.eventNotifications.filter((n: any) => !n.sendTime || n.sendTime === null);
    }
  }

  get noCycle(): boolean {
    return CommonUtils.isTrue(this.$store.state.events.noCycle);
  }

  set noCycle(val: boolean) {
    this.$store.commit('events/setNoCycle', CommonUtils.booleanToString(val));
    this.addUpdatedField('noCycle');
  }

  get privateFlag(): boolean {
    return CommonUtils.isTrue(this.$store.state.events.privateFlag);
  }

  set privateFlag(val: boolean) {
    this.$store.commit('events/setPrivateFlag', CommonUtils.booleanToString(val));
    this.addUpdatedField('privateFlag');
  }

  get attachments(): Array<any> {
    return this.$store.state.events.attachments || [];
  }

  set attachments(val: Array<any>) {
    this.$store.commit('events/setAttachments', val);
    this.addUpdatedField('attachments');
  }

  get attachmentItems() {
    return this.attachments.map((a, i) => {
      const name = (a.name || a.filename || a.fileName || '');
      return {
        key: name + i,
        shortValue: '',
        value: name,
        data: a
      }
    })
  }

  get updatedFields(): Set<any> {
    return this.$store.state.events.updatedFields;
  }

  set updatedFields(val: Set<any>) {
    this.$store.commit('events/setUpdatedFields', val);
  }

  get eventId(): number {
    return +this.$store.state.events.eventId;
  }

  set eventId(val: number) {
    this.$store.commit('events/setEventId', val);
  }

  get verifyShift(): boolean {
    return this.$store.state.events.verifyShift;
  }

  set verifyShift(val: boolean) {
    this.$store.commit('events/setVerifyShift', val);
  }

  get extraDays(): Array<any> {
    return this.$store.state.events.extraDays;
  }

  set extraDays(val: Array<any>) {
    this.$store.commit('events/setExtraDays', val);
    this.addUpdatedField('extraDays');
  }

  get noEventDays(): Array<any> {
    return this.$store.state.events.noEventDays;
  }

  set noEventDays(val: Array<any>) {
    this.$store.commit('events/setNoEventDays', val);
    this.addUpdatedField('noEventDays');
  }

  get occurrences() {
    return occurrences;
  }

  get showRepeats() {
    return CommonUtils.hasText(this.eventDate) && CommonUtils.hasText(this.endDate) && DateTimeUtils.daysDiff(this.eventDate, this.endDate) > 7;
  }

  get showSpecialDay() {
    return this.$currentUser.isTeacher && this.isEditable && this.specialDayItems.length > 1;
  }

  get didRecurringEventChanged() {
    return this.$store.state.events.origEvent.eventDate !== this.eventDate || this.$store.state.events.origEvent.endDate !== this.endDate || this.$store.state.events.origEvent.repeats !== this.repeats;
  }

  get shouldUpdateCurrentEvent() {
    return this.eventDate !== this.endDate || CommonUtils.isNotEmpty(this.extraDays);
  }

  get didCustomDaysChanged() {
    const origAddedDays = ld.cloneDeep(this.origEvent.extraDays) || [];
    const origRemoveDays = ld.cloneDeep(this.origEvent.noEventDays) || [];
    const extraDays = ld.cloneDeep(this.extraDays);
    const noEventDays = ld.cloneDeep(this.noEventDays);
    return CommonUtils.isArrayNotEqual(origAddedDays, extraDays) || CommonUtils.isArrayNotEqual(origRemoveDays, noEventDays);
  }

  get showNoCycle() {
    return this.isCycleSchedule && !this.noSchool;
  }

  get noSchoolNoCycleDisabled() {
    if (((CommonUtils.hasText(this.eventDate) && DateTimeUtils.daysDiff(this.eventDate, DateTimeUtils.currentDateAddDays(0)) > 30) || this.hasAddDaysInThePast || this.hasRemoveDaysInThePast) && !this.isSchoolYearNew) {
      this.forceCannotEditEventMsg = true;
      if (this.eventId === 0 || !this.isFieldRestricted) {
        this.noSchool = false;
        this.noCycle = false;
      }
      return true;
    }
    this.forceCannotEditEventMsg = false;
    return false;
  }

  get isSchoolYearNew() {
    if (this.$currentUser.isTeacher && DateTimeUtils.daysDiff(this.getCurrentYear.created, DateTimeUtils.formatDate(moment().toDate(), 'MM/DD/YYYY')) <= 7) {
      return true;
    }
    return false;
  }

  get hasAddDaysInThePast() {
    return !this.addDaysHolder.every(e => CommonUtils.hasNoText(e.value) || DateTimeUtils.daysDiff(e.value, DateTimeUtils.currentDateAddDays(0)) <= 30);
  }

  get hasRemoveDaysInThePast() {
    return !this.removeDaysHolder.every(e => CommonUtils.hasNoText(e.value) || DateTimeUtils.daysDiff(e.value, DateTimeUtils.currentDateAddDays(0)) <= 30);
  }

  get isEventDateRange(): boolean {
    if ((this.eventDate !== this.endDate && DateTimeUtils.daysDiff(this.eventDate, this.endDate) > 0)) {
      return true;
    }
    this.removeDaysHolder = [];
    this.onRemoveDaysHolderChange();
    return false;
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.errorMsg);
  }

  get hasAttachments() {
    return CommonUtils.isNotEmpty(this.attachments);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  dateError = '';
  dateRules = [() => ''];
  timeError = '';
  timeRules = [() => ''];

  validateEventDates() {
    this.dateError = '';
    if (CommonUtils.hasNoText(this.eventDate)) {
      this.dateError = this.$t('enterStartDate').toString();
    } else if (!DateTimeUtils.isDateFormatValid(this.eventDate)) {
      this.dateError = this.$t('enterValidStartDate').toString();
    } else if (CommonUtils.hasNoText(this.endDate)) {
      this.dateError = this.$t('requiredMsg', { s: 'End Date' }).toString();
    } else if (!DateTimeUtils.isDateFormatValid(this.endDate)) {
      this.dateError = this.$t('enterValidEndDate').toString();
    } else if (DateTimeUtils.daysDiff(this.eventDate, this.endDate) < 0) {
      this.dateError = this.$t('startMustBeBeforeEnd').toString();
    } else if (DateTimeUtils.daysDiff(this.eventDate, this.endDate) > 365) {
      this.dateError = this.$t('eventErrorMsg1').toString();
    }
    this.dateRules = [() => this.dateError];
  }

  validateEventTimes() {
    this.timeError = '';
    if (CommonUtils.hasText(this.eventStartTime) && !DateTimeUtils.isTimeFormatValid(this.eventStartTime)) {
      this.timeError = this.$t('enterValidStartTime').toString();
    } else if (CommonUtils.hasText(this.eventEndTime) && !DateTimeUtils.isTimeFormatValid(this.eventEndTime)) {
      this.timeError = this.$t('enterValidEndTime').toString();
    }
    this.timeRules = [() => this.timeError];
  }

  get cachedErrorMsg() {
    if (CommonUtils.isNotEmpty(this.noEventDays)) {
      for (const i in this.noEventDays) {
        const eventDay = this.noEventDays[i];
        if (eventDay !== '') {
          if (this.repeats === 'weekly' && !DateTimeUtils.isInWeeklySchedule(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg2');
          } else if (this.repeats === 'biweekly' && !DateTimeUtils.isInBiweeklySchedule(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg2');
          } else if (this.repeats === 'daily' && !DateTimeUtils.isBetween(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg2');
          }
        }
      }
    }
    if (CommonUtils.isNotEmpty(this.extraDays)) {
      for (const i in this.extraDays) {
        const eventDay = this.extraDays[i];
        if (eventDay !== '') {
          if (this.repeats === 'weekly' && DateTimeUtils.isInWeeklySchedule(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg5');
          } else if (this.repeats === 'biweekly' && DateTimeUtils.isInBiweeklySchedule(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg5');
          } else if (this.repeats === 'daily' && DateTimeUtils.isBetween(eventDay, this.eventDate, this.endDate)) {
            return this.$t('eventErrorMsg5');
          }
        }
      }
    }
    return '';
  }

  get hasRemoveDays() {
    return CommonUtils.isNotEmpty(this.removeDaysHolder);
  }

  get hasAddDays() {
    return CommonUtils.isNotEmpty(this.addDaysHolder);
  }

  removeEventSticker() {
    this.$store.commit('events/setEventStickerId', 0);
  }

  editSticker() {
    if (this.isEditable) {
      this.isStickersPickerOpen = true;
    }
  }

  deleteAttachment(item: any, index: number) {
    this.attachments = this.attachments.filter((s, i) => i !== index);
  }

  toggleAttachmentPrivateFlag(item: any, index: number) {
    const newAttachments = ld.cloneDeep(this.attachments);
    const attachment = newAttachments.find((a, i) => i === index);
    attachment.privateFlag = !attachment.privateFlag;
    ld.set(newAttachments, index, attachment);
    this.attachments = newAttachments;
  }

  addGoogleDriveAttachments() {
    this.$google.setAuthToken(UserSession.getGoogleAuthToken());
    this.$google.openPicker((data: any) => {
      this.updateAttachments([{
        filename: data.name,
        fileType: 'drive',
        url: data.url,
        id: data.id,
        privateFlag: false
      }])
    });
  }

  dropAttachment(e: any) {
    e.preventDefault();
    this.localActiveTab = 1;
    const fileCount = e.dataTransfer.files.length;
    const droppedFiles = [];
    for (let i = 0; i < fileCount; i++) {
      droppedFiles.push(e.dataTransfer.files[i]);
    }
    this.$refs.filesPicker.appendFiles(droppedFiles);
    this.$refs.filesPicker.saveFiles();
  }

  clearAttachments() {
    const attachmentsLabel = this.$t('attachmentsLabel') as string;
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: attachmentsLabel }),
      text: this.$t('clearAllMsg', { text: attachmentsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.attachments = [];
      }
    });
  }

  addUpdatedField(field: string) {
    if (this.initialized) {
      this.updatedFields.add(field);
    }
  }

  openDialog(type: string) {
    for (const key in this.dialogs) {
      this.dialogs[key] = type === key;
    }
  }

  openLink(attachment: any) {
    const url = attachment.url;
    const name = attachment.name || attachment.filename || attachment.fileName;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', name);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', this.userInfo.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', '0');
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  async clearRemoveDays() {
    return this.$refs.confirm.confirm({
      title: this.$t('removeDaysLabel'),
      text: this.$t('removeAllDaysMsg'),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.removeDaysHolder = [];
      }
    });
  }

  addRemoveDays() {
    this.removeDaysHolder.push({
      id: 'removeDay' + CommonUtils.generateUUID(),
      value: '',
      new: true
    })
  }

  deleteRemoveDays(i: number) {
    this.removeDaysHolder.splice(i, 1);
  }

  async clearAddDays() {
    return this.$refs.confirm.confirm({
      title: this.$t('addDaysLabel'),
      text: this.$t('removeAllDaysMsg'),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.addDaysHolder = [];
      }
    });
  }

  addAddDays() {
    this.addDaysHolder.push({
      id: 'addDay' + CommonUtils.generateUUID(),
      value: '',
      new: true
    })
  }

  deleteAddDays(i: number) {
    this.addDaysHolder.splice(i, 1);
  }

  async doBeforeSave() {
    this.onRemoveDaysHolderChange();
    this.onAddDaysHolderChange();
    this.initialized = false;
    return Promise.resolve().then(() => {
      this.updateCurrentEvent = false;
      this.verifyShift = true;
      this.removeDaysHolder = this.removeDaysHolder.filter(e => CommonUtils.hasText(e.value));
      this.addDaysHolder = this.addDaysHolder.filter(e => CommonUtils.hasText(e.value));
    }).then(() => {
      this.initialized = true;
      return Promise.resolve();
    });
  }

  public async save() {
    if (!this.isEditable) {
      return this.doSaveNotifications();
    }
    this.errorMsg = this.cachedErrorMsg as string || '';
    this.validateEventDates();
    this.validateEventTimes();
    if (this.isCycleSchedule && this.noSchool) this.noCycle = true;
    return new Promise((resolve) => {
      this.$nextTick(() => {
        resolve({});
      });
    }).then(async () => {
      return this.doBeforeSave().then(async () => {
        if (this.eventNotificationsItems.length > 0 && !this.getPushNotificationsEnabled) {
          return this.$refs.confirm.confirm({
            title: this.$t('notificationsLabel'),
            text: this.$t('notificationDisabledWarning'),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then((result) => {
            if (result === 1) {
              return Promise.resolve();
            } else {
              return Promise.reject(new FormError());
            }
          });
        }
      }).then(async () => {
        if (this.eventId > 0) {
          if (this.didRecurringEventChanged) {
            return this.$refs.confirm.confirm({
              title: this.$t('editEventLabel'),
              text: this.$t('editEventMsg1'),
              option1ButtonAlternativeText: this.$t('continueLabel')
            }).then((result) => {
              if (result === 1) {
                return Promise.resolve();
              } else {
                return Promise.reject(new FormError());
              }
            });
          } else if (this.shouldUpdateCurrentEvent) {
            return this.$refs.confirm.confirm({
              title: this.$t('editEventLabel'),
              text: this.$t('editEventMsg2', { text: DateTimeUtils.formatToShow(this.eventCurrentDate) }),
              option1ButtonAlternativeText: this.$t('allEventsLabel'),
              option2ButtonAlternativeText: this.$t('onlyThisEventLabel')
            }).then((result) => {
              if (result === 1) {
                return Promise.resolve();
              } else if (result === 2) {
                this.updateCurrentEvent = true;
                return Promise.resolve();
              } else {
                return Promise.reject(new FormError());
              }
            });
          } else {
            return Promise.resolve();
          }
        } else {
          return Promise.resolve();
        }
      }).then(async () => {
        if (CommonUtils.hasNoText(this.errorMsg) && CommonUtils.hasNoText(this.timeError) && CommonUtils.hasNoText(this.dateError)) {
          if (this.noSchool && DateTimeUtils.daysDiff(this.eventDate, this.endDate) > 14) {
            return this.$refs.confirm.confirm({
              title: this.$t('noSchoolEventLabel'),
              text: this.$t('noSchoolEventMsg1'),
              option1ButtonAlternativeText: this.$t('createNoSchoolEventLabel')
            }).then((result) => {
              if (result === 1) {
                return Promise.resolve();
              } else {
                return Promise.reject(new FormError());
              }
            });
          } else {
            return Promise.resolve();
          }
        } else {
          return Promise.reject(new FormError());
        }
      }).then(() => {
        if (this.userMode === 'T') {
          if (this.noSchool && this.didCustomDaysChanged) {
            return this.confirmShiftLessons(this.$t('shiftLessonsMsg1') as string);
          } else if (this.noSchool && !this.origEvent.noSchool) {
            return this.confirmShiftLessons(this.$t('shiftLessonsMsg2') as string);
          } else if (this.noSchool && (this.eventCurrentDate !== this.eventDate || this.eventCurrentDate !== this.endDate)) {
            return this.confirmShiftLessons(this.$t('shiftLessonsMsg3') as string);
          } else if (!this.noSchool && this.origEvent.noSchool) {
            return this.confirmShiftLessons(this.$t('shiftLessonsMsg4') as string);
          } else if (this.origEvent.noCycle !== this.noCycle || (this.noCycle && (this.eventCurrentDate !== this.eventDate || this.eventCurrentDate !== this.endDate))) {
            this.shiftLessons = true;
            return Promise.resolve();
          } else {
            this.shiftLessons = false;
            return Promise.resolve();
          }
        } else {
          this.shiftLessons = false;
          return Promise.resolve();
        }
      }).then(() => {
        this.localPage === 'events' ? this.$store.commit('events/setListLoading', true) : CommonUtils.showLoading();
        return this.doSaveAndNotify();
      }).then(async () => {
        this.onStickerEditorClosed();
        CommonUtils.hideLoading();
        if (CommonUtils.isNotEmpty(this.deletedLessons)) {
          let deleteText = '';
          if (this.deletedLessons.length > 1) {
            deleteText += this.deletedLessons.length + (' ' + this.$t('lessonsLabel')).toLocaleLowerCase();
          } else {
            deleteText += this.$t('aLessonLabel');
          }
          return this.$refs.confirm.confirm({
            title: this.$t('lessonLabel'),
            text: this.$t('confirmDeleteOnEventChangeMsg', { text: deleteText }),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then(result => {
            if (result === 1) {
              this.verifyShift = false;
              this.localPage === 'events' ? this.$store.commit('events/setListLoading', true) : CommonUtils.showLoading();
              return this.doSaveAndNotify();
            } else {
              return Promise.reject(new FormError());
            }
          });
        } else {
          this.errorMsg = '';
          this.dateError = '';
          this.timeError = '';
          return Promise.resolve();
        }
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    });
  }

  async doSaveNotifications() {
    return Promise.resolve().then(async () => {
      if (this.eventNotificationsItems.length > 0 && !this.getPushNotificationsEnabled) {
        return this.$refs.confirm.confirm({
          title: this.$t('notificationsLabel'),
          text: this.$t('notificationDisabledWarning'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            return Promise.resolve();
          } else {
            return Promise.reject(new FormError());
          }
        });
      }
    }).then(() => {
      if (this.shouldUpdateCurrentEvent) {
        return this.$refs.confirm.confirm({
          title: this.$t('editEventLabel'),
          text: this.$t('editEventMsg2', { text: DateTimeUtils.formatToShow(this.eventCurrentDate) }),
          option1ButtonAlternativeText: this.$t('allEventsLabel'),
          option2ButtonAlternativeText: this.$t('onlyThisEventLabel')
        }).then((result) => {
          if (result === 1) {
            return Promise.resolve();
          } else if (result === 2) {
            this.updateCurrentEvent = true;
            return Promise.resolve();
          } else {
            return Promise.reject(new FormError());
          }
        });
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      const message = this.eventId > 0 ? this.$t('statusMsg15a') as string : this.$t('statusMsg14') as string;
      return this.saveNotifications({ allEvents: !this.updateCurrentEvent }).then(resp => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(message);
        }
        return Promise.resolve(resp);
      })
    })
  }

  async doSaveAndNotify() {
    const message = this.eventId > 0 ? this.$t('statusMsg15') as string : this.$t('statusMsg14') as string;
    return this.doSave().then(resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(message);
      }

      return Promise.resolve(resp);
    })
  }

  async confirmShiftLessons(message: string) {
    if (this.isCycleSchedule) {
      this.shiftLessons = true;
      return Promise.resolve();
    } else {
      return this.$refs.confirm.confirm({
        title: this.$t('noSchoolEventLabel'),
        text: message,
        option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
        option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
      }).then((result) => {
        if (result === 1) {
          this.shiftLessons = true;
          return Promise.resolve();
        } else if (result === 2) {
          this.shiftLessons = false;
          return Promise.resolve();
        } else {
          return Promise.reject(new FormError());
        }
      });
    }
  }

  resetValidation() {
    this.errorMsg = '';
    this.dateError = '';
    this.dateRules = [() => ''];
    this.timeError = '';
    this.timeRules = [() => ''];
  }

  @Watch('removeDaysHolder', { deep: true })
  onRemoveDaysHolderChange() {
    if (this.initialized) {
      this.noEventDays = this.removeDaysHolder.filter(r => CommonUtils.hasText(r.value)).map(a => a.value);
    }
  }

  @Watch('addDaysHolder', { deep: true })
  onAddDaysHolderChange() {
    if (this.initialized) {
      this.extraDays = this.addDaysHolder.filter(r => CommonUtils.hasText(r.value)).map(a => a.value);
    }
  }

  @Watch('input')
  doInit() {
    this.initialized = false;
    this.localRefreshKey = CommonUtils.generateUUID();
    this.resetValidation();
    this.init(this.input).then(() => {
      this.removeDaysHolder = [];
      for (const i in this.noEventDays) {
        const removeDay = this.noEventDays[i];
        this.removeDaysHolder.push({
          id: 'removeDay' + CommonUtils.generateUUID(),
          value: removeDay
        })
      }

      this.addDaysHolder = [];
      for (const i in this.extraDays) {
        const addDay = this.extraDays[i];
        this.addDaysHolder.push({
          id: 'addDay' + CommonUtils.generateUUID(),
          value: addDay
        })
      }
      this.resetValidation();
      return Promise.resolve();
    }).then(() => {
      if (this.isActive) {
        return this.loadSpecialDays();
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      this.initialized = true;
      if (this.input.openStickerPickerDefault) {
        this.editSticker();
      }
    });
  }

  updateAttachments(files: Array<any>) {
    const extensions = ['ico', 'png', 'jpeg', 'jpg', 'svg', 'tif', 'webp'];
    files.forEach(a => {
      if (!this.attachmentExists(a) && !extensions.includes(this.getExtension(a))) {
        this.attachments.push(a);
        this.addUpdatedField('attachments');
      }
    });
    const imageFiles = files.filter(a => extensions.includes(this.getExtension(a)));
    if (imageFiles.length > 0) {
      return this.$refs.confirm.confirm({
        title: this.$t('imageActionsLabel'),
        text: this.$t('imageActionsMsg'),
        option1ButtonAlternativeText: this.$t('attachLabel'),
        option2ButtonAlternativeText: this.$t('embedLabel')
      }).then(async result => {
        if (result === 1) {
          files.forEach(a => {
            if (!this.attachmentExists(a)) {
              this.attachments.push(a);
              this.addUpdatedField('attachments');
            }
          });
        } else if (result === 2) {
          imageFiles.forEach((img: any) => {
            let url = img.url;
            if (FileServices.isGoogleDriveFile(url)) {
              const driveId = FileServices.guessGoogleDriveId(url);
              if (CommonUtils.hasText(driveId)) {
                url = `https://drive.google.com/uc?id=${driveId}&export=download`;
              }
            }
            this.eventText += `<p><img style="width: 500px; max-width: 100%; height: auto;" src="${url}" alt="Image"/></p>`;
          });
          this.localActiveTab = 0;
        }
        return Promise.resolve();
      });
    }
  }

  attachmentExists(file: any) {
    return this.attachments.some(a => this.getFileName(a) === this.getFileName(file));
  }

  getExtension(file: any) {
    const name = this.getFileName(file);
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
  }

  getFileName(file: any) {
    return file.fileName || file.filename || file.name || '';
  }

  created() {
    this.doInit();
  }

  onStickerEditorClosed() {
    if (this.input.openStickerPickerDefault) {
      this.$emit('stickerPickerClosed');
    }
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.$eventBus.$on('addEventSticker', () => {
        that.editSticker();
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('addEventSticker');
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }
}
