var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: { withActions: false, expandable: false, "max-width": 600 },
      on: { apply: _vm.addCustomNotificaiton },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("customNotificationLabel")))]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-form",
            { ref: "customNotification" },
            [
              !_vm.dayEvent
                ? _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-text-field", {
                            key: _vm.localRefreshKey,
                            attrs: {
                              outlined: "",
                              type: "number",
                              rules: _vm.filteredTimeRules,
                              dense: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.refreshKey.apply(null, arguments)
                              },
                              mousedown: function ($event) {
                                return _vm.setIsMouseUp(false)
                              },
                              mouseup: function ($event) {
                                return _vm.setIsMouseUp(true)
                              },
                              keydown: function ($event) {
                                return _vm.setIsMouseUp(false)
                              },
                            },
                            model: {
                              value: _vm.selectedTimeAmount,
                              callback: function ($$v) {
                                _vm.selectedTimeAmount = $$v
                              },
                              expression: "selectedTimeAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.timeType,
                              "item-text": "title",
                              "return-object": "",
                              dense: "",
                            },
                            model: {
                              value: _vm.selectedTimeType,
                              callback: function ($$v) {
                                _vm.selectedTimeType = $$v
                              },
                              expression: "selectedTimeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "2", align: "center" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "mt-2",
                            staticStyle: {
                              "font-size": "16px",
                              color: "#000000",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("beforeLabel")))]
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            key: _vm.localRefreshKey,
                            attrs: {
                              outlined: "",
                              type: "number",
                              rules: _vm.filteredTimeRules,
                              dense: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.refreshKey.apply(null, arguments)
                              },
                              mousedown: function ($event) {
                                return _vm.setIsMouseUp(false)
                              },
                              mouseup: function ($event) {
                                return _vm.setIsMouseUp(true)
                              },
                              keydown: function ($event) {
                                return _vm.setIsMouseUp(false)
                              },
                            },
                            model: {
                              value: _vm.selectedTimeAmount,
                              callback: function ($$v) {
                                _vm.selectedTimeAmount = $$v
                              },
                              expression: "selectedTimeAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.timeType,
                              "item-text": "title",
                              "return-object": "",
                              dense: "",
                            },
                            model: {
                              value: _vm.selectedTimeType,
                              callback: function ($$v) {
                                _vm.selectedTimeType = $$v
                              },
                              expression: "selectedTimeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "2", align: "center" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "mt-2",
                            staticStyle: {
                              "font-size": "16px",
                              color: "#000000",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("beforeAtLabel")))]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("pb-time-field", {
                            attrs: {
                              outlined: true,
                              dense: true,
                              value: _vm.customTime,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.customTime = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }