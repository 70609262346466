var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type !== "E" || !_vm.rawDayObject.hidden
    ? _c(
        "v-sheet",
        {
          ref: "sectionCard",
          staticClass: "section-card",
          class: {
            "section-card-draggable": _vm.enableDragDrop && _vm.type === "L",
            "hide-details": !_vm.localShowDetails,
            "show-details": _vm.localShowDetails,
            hover: _vm.hover,
            "section-card-white": _vm.isColorWhite
          },
          staticStyle: { position: "relative" },
          style: _vm.localStyle,
          attrs: {
            id: _vm.sectionCardId,
            elevation: _vm.hover ? 12 : undefined,
            tabindex: _vm.$currentUser.isTeacher ? 0 : -1,
            role: "link"
          },
          on: {
            mouseenter: _vm.onMouseEnter,
            mouseleave: _vm.onMouseLeave,
            contextmenu: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onContextMenu($event, _vm.localDayObject)
            },
            focus: function($event) {
              _vm.hover = true
            },
            blur: function($event) {
              _vm.hover = false
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onClick($event, _vm.localDayObject)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "section-card-header",
              style: {
                cursor: _vm.$currentUser.isStudent ? "normal" : undefined
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onClick($event, _vm.localDayObject)
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onClick($event, _vm.localDayObject)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "section-card-label",
                  style: _vm.localObjectStyling
                },
                [
                  _vm.haveStickers
                    ? _c("event-sticker-image", {
                        style:
                          _vm.stickerBackgroundStyle == "1" ||
                          _vm.stickerBackgroundStyle == "5"
                            ? "margin-right: 5px;"
                            : "margin-right: 10px;",
                        attrs: {
                          stickerURL: _vm.stickerURL,
                          stickerBackgroundStyle: _vm.stickerBackgroundStyle,
                          stickerSize: _vm.stickerSize,
                          stickerBackgroundColor: _vm.stickerBackgroundColor
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "my-auto",
                      style: _vm.haveStickers
                        ? "display: inline-block; vertical-align: middle;"
                        : ""
                    },
                    [
                      _vm.classHeadOrder === "2"
                        ? _c(
                            "span",
                            {
                              class: _vm.highContrastMode ? "contrast-view" : ""
                            },
                            [
                              _vm.localDayObject.showTimes
                                ? _c("div", {
                                    staticClass: "section-card-label__time",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.localTime)
                                    }
                                  })
                                : _vm._e(),
                              _c("div", {
                                domProps: { innerHTML: _vm._s(_vm.localName) }
                              })
                            ]
                          )
                        : _c(
                            "span",
                            {
                              class: _vm.highContrastMode ? "contrast-view" : ""
                            },
                            [
                              _c("div", {
                                domProps: { innerHTML: _vm._s(_vm.localName) }
                              }),
                              _vm.localDayObject.showTimes
                                ? _c("div", {
                                    staticClass: "section-card-label__time",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.localTime)
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.actions &&
                        _vm.localDayObject.showActions &&
                        !_vm.$currentUser.isStudent,
                      expression:
                        "actions && localDayObject.showActions && !$currentUser.isStudent"
                    }
                  ],
                  staticClass: "section-card-actions",
                  style: { "background-color": _vm.color }
                },
                [
                  _vm.isShowLock
                    ? _c(
                        "div",
                        {
                          staticClass: "section-card-action-button",
                          style: _vm.shiftStyle
                        },
                        [
                          _c("i", {
                            staticClass: "section-card-action-icon fas fa-lock"
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isShowGoogle
                    ? _c("div", { staticClass: "section-card-action-button" }, [
                        _c("i", {
                          staticClass: "section-card-action-icon fab fa-google"
                        })
                      ])
                    : _vm._e(),
                  _vm.rawDayObject &&
                  _vm.rawDayObject.linkedLessonId &&
                  _vm.rawDayObject.linkedLessonId > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "section-card-action-button p-l-0 p-r-0 section-edit-link-lesson",
                          style: _vm.shiftStyle,
                          attrs: {
                            tabindex: _vm.$currentUser.isTeacher ? 0 : -1,
                            role: "button",
                            "aria-label": _vm.$t("editLinkedLessoLabel")
                          },
                          on: {
                            focus: function($event) {
                              _vm.hover = true
                            },
                            blur: function($event) {
                              _vm.hover = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "section-card-action-icon far fa-link"
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.hover &&
                  _vm.rawDayObject &&
                  _vm.rawDayObject.collaborateSubjectId > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "section-card-action-button p-l-0 p-r-0 section-replica-class"
                        },
                        [
                          _c("i", {
                            staticClass: "section-card-action-icon far fa-r"
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isShowComment > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "section-card-action-button",
                          style: _vm.shiftStyle,
                          attrs: {
                            tabindex: "0",
                            role: "button",
                            "aria-label": _vm.$t("viewCommentLabel")
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openComment(_vm.isShowComment)
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openComment(_vm.isShowComment)
                            },
                            focus: function($event) {
                              _vm.hover = true
                            },
                            blur: function($event) {
                              _vm.hover = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "section-card-action-icon fas fa-comment"
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.enableDragDrop &&
                  _vm.isLesson &&
                  _vm.userMode === "T" &&
                  _vm.rawDayObject.collaborateSubjectId == 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "section-card-action-button section-card-draggable-handle",
                          staticStyle: { cursor: "move" }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "section-card-action-icon far fa-up-down-left-right",
                            style: _vm.iconColorOnHover(_vm.hover)
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.hasCustomActions &&
                  !_vm.isReadonly &&
                  !_vm.isShowGoogle &&
                  (_vm.isLesson ||
                    (_vm.isEvent &&
                      (!_vm.$currentUser.isAdmin ||
                        _vm.localDayObject.schoolId))) &&
                  !(
                    _vm.rawDayObject.collaborateSubjectId > 0 &&
                    _vm.rawDayObject.collaborateType !== 2
                  )
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$currentUser.isStudent,
                              expression: "!$currentUser.isStudent"
                            }
                          ],
                          staticClass:
                            "section-card-action-button section-actions section-show-actions",
                          staticStyle: {
                            "padding-left": "8px",
                            "padding-right": "8px"
                          },
                          attrs: {
                            tabindex: "0",
                            role: "button",
                            "aria-label": _vm.$t("actionsLabel")
                          },
                          on: {
                            focus: function($event) {
                              _vm.hover = true
                            },
                            blur: function($event) {
                              _vm.hover = false
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "section-card-action-icon fas fa-ellipsis-v",
                            style: _vm.iconColorOnHover(_vm.hover)
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.hasCustomActions &&
                  (_vm.hasLessonActions || _vm.hasEventActions)
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$currentUser.isStudent,
                              expression: "!$currentUser.isStudent"
                            }
                          ],
                          staticClass:
                            "section-card-action-button section-actions section-show-actions",
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _vm.hasLessonActions
                            ? _c("lesson-actions", {
                                attrs: {
                                  type: "custom",
                                  input: _vm.lessonInput
                                },
                                on: {
                                  focus: function($event) {
                                    _vm.hover = true
                                  },
                                  blur: function($event) {
                                    _vm.hover = false
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "customButton",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-left": "8px",
                                                "padding-right": "8px"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "section-card-action-icon fas fa-ellipsis-v",
                                                style: _vm.iconColorOnHover(
                                                  _vm.hover
                                                )
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1293609347
                                )
                              })
                            : _vm._e(),
                          _vm.hasEventActions
                            ? _c("event-actions", {
                                attrs: {
                                  type: "custom",
                                  input: _vm.getEventInput()
                                },
                                on: {
                                  focus: function($event) {
                                    _vm.hover = true
                                  },
                                  blur: function($event) {
                                    _vm.hover = false
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "customButton",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-left": "8px",
                                                "padding-right": "8px"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "section-card-action-icon fas fa-ellipsis-v",
                                                style: _vm.iconColorOnHover(
                                                  _vm.hover
                                                )
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1293609347
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "section-card-contents",
              on: {
                dblclick: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onClick($event, _vm.localDayObject)
                },
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onBodyClick($event, _vm.localDayObject)
                }
              }
            },
            _vm._l(_vm.sections, function(section, i) {
              return _c(section.type, {
                key: "section-card-content" + i,
                tag: "component",
                attrs: {
                  showDetails: _vm.localShowDetails,
                  section: section,
                  color: _vm.color,
                  hover: _vm.hover,
                  teacherId: _vm.dayObject.teacherId
                }
              })
            }),
            1
          ),
          _c("pb-confirm", { ref: "confirm" }),
          _vm.hasContent
            ? _c(
                "v-btn",
                {
                  staticStyle: {
                    "z-index": "1",
                    position: "absolute",
                    bottom: "-2px",
                    left: "50%",
                    right: "50%"
                  },
                  attrs: {
                    icon: "",
                    "x-small": "",
                    color: _vm.toggleShowDetailsColor,
                    "aria-label": _vm.$t("showContentLabel")
                  },
                  on: {
                    focus: function($event) {
                      _vm.hover = true
                    },
                    blur: function($event) {
                      _vm.hover = false
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toggleShowDetails.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      style: _vm.iconColorOnHover(_vm.hover),
                      attrs: { "x-small": "" }
                    },
                    [
                      _vm.localShowDetails
                        ? [_vm._v("fas fa-angle-up")]
                        : [_vm._v("fas fa-angle-down")]
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }