
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Deferred } from '@/common/deferred';
import { VForm } from 'vuetify/lib';
import { timeTypes } from '@/constants'
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';

const events = namespace('events');

@Component
export default class EventNotification extends Vue {
    @Prop({ required: true, type: String, default: 'P' })
    mode!: string;

    @Prop({ required: true, default: {} })
    data!: any;

    @events.State
    eventStartTime!: string;

    @events.Action('updateNotifications')
    saveNotifications!: (params?: any) => Promise<any>;

    get dayEvent() {
      return (this.eventStartTime === null || CommonUtils.hasNoText(this.eventStartTime));
    }

    get timeType() {
      if (this.dayEvent) {
        return timeTypes.filter((t) => t.type === 'Day');
      } else {
        return timeTypes;
      }
    }

    timeSelected = '0';
    selectedTimeAmount = 0;
    selectedTimeType = this.timeType[0];
    customTime = '9:00 AM';
    customError = () => '';

    deferred = new Deferred();
    showMenu = false;
    x = 0;
    y = 0;

    localRefreshKey = CommonUtils.generateUUID();
    isSelectedTimeAmountChanged = false;
    isMouseUp = false;

    $refs!: {
      customNotification: InstanceType<typeof VForm>
    }

    @Watch('showMenu')
    onShowMenuChange() {
      if (this.showMenu) {
        this.deferred = new Deferred();
      }
    }

    @Watch('selectedTimeAmount')
    onSelectedTimeAmountChange() {
      this.isSelectedTimeAmountChanged = true;
      if (this.isMouseUp) {
        this.localRefreshKey = CommonUtils.generateUUID();
      }
    }

    setIsMouseUp(isMouseUp: boolean) {
      this.isMouseUp = isMouseUp;
    }

    addNotification(item: any) {
      this.appendNotification(item);
      this.close();
    }

    openCustomNotificaiton() {
      this.selectedTimeAmount = 0;
      this.selectedTimeType = this.timeType[0];
    }

    get filteredTimeRules() {
      return this.timeRules.concat((t: any) => (t * this.selectedTimeType.value <= 40320) || this.$t('notificationTimeRange'))
    }

    timeRules = [
      (t: any) => (!!t || t === 0) || this.$t('numberError'),
      (t: any) => (t % 1 === 0) || this.$t('wholeNumberError'),
      (t: any) => (t >= 0) || this.$t('numberPositive')
    ]

    addCustomNotificaiton() {
      const form: any = this.$refs.customNotification
      if (form.validate()) {
        const item: any = {
          title: this.$t(this.selectedTimeType.string, { x: this.selectedTimeAmount }),
          display: this.selectedTimeType.display,
          minutesPrior: this.selectedTimeAmount * this.selectedTimeType.value,
          sendTime: null
        }
        if (this.dayEvent) {
          item.title = item.title + this.$t('notificationAtTime', { time: this.customTime }).toString();
          item.sendTime = this.customTime;
        }
        this.appendNotification(item);
        this.close();
        this.showMenu = false;
      }
    }

    close() {
      this.deferred.resolve();
    }

    appendNotification(item: any) {
      if (this.mode === 'P') {
        this.saveNotifications({
          eventId: this.data.eventId,
          customEventId: this.data.customEventId,
          allEvents: false,
          mode: 'A',
          notificationUnit: item.display,
          notificaitonMinutesPrior: item.minutesPrior,
          notificationTime: item.sendTime || ''
        })
      } else {
        this.$store.commit('events/appendNotification', item);
      }
    }

    public show() {
      this.showMenu = true;
      this.openCustomNotificaiton();
    }

    refreshKey() {
      this.localRefreshKey = CommonUtils.generateUUID();
      if (this.isSelectedTimeAmountChanged) {
        this.isSelectedTimeAmountChanged = false;
        this.localRefreshKey = CommonUtils.generateUUID();
      }
    }
}
