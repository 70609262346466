var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragleave: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      !_vm.input.openStickerPickerDefault
        ? _c(
            "v-form",
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible",
                    },
                  ],
                  staticClass: "px-5 pt-4 pb-2",
                },
                [
                  _c(
                    "v-expand-transition",
                    [
                      _vm.hasErrorMsg
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-6",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        dense: "",
                                        border: "left",
                                        type: "error",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  (_vm.isEditable && _vm.isFieldRestricted) ||
                  _vm.forceCannotEditEventMsg
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            icon: "fa-solid fa-circle-info",
                            border: "left",
                            type: "info",
                            rounded: "",
                            dense: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.cannotEditEventMsg) + " ")]
                      )
                    : _vm._e(),
                  _vm.hasText(_vm.stickerURL)
                    ? _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    outlined: "",
                                    width: "100%",
                                    height: "140px",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                        tabindex: "0",
                                        role: "button",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.editSticker.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "title-on-border" },
                                        [_vm._v(_vm._s(_vm.$t("stickerLabel")))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            role: "button",
                                            "aria-label":
                                              _vm.$t("editStickerLabel"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.editSticker.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("event-sticker-image", {
                                            attrs: {
                                              stickerURL: _vm.stickerURL,
                                              stickerBackgroundStyle:
                                                _vm.stickerBackgroundStyle,
                                              stickerSize: "55",
                                              stickerBackgroundColor:
                                                _vm.stickerBackgroundColor,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "justify-center" },
                                    [
                                      _vm.hasText(_vm.stickerURL) &&
                                      _vm.isEditable
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                "margin-top": "-6px",
                                              },
                                              attrs: {
                                                color: "error",
                                                small: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeEventSticker.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("removeLabel"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "9" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          autofocus: "",
                                          "hide-details": "auto",
                                          readonly: !_vm.isEditable,
                                          disabled: !_vm.isEditable,
                                          label: _vm.$t("titleLabel"),
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.eventTitle,
                                          callback: function ($$v) {
                                            _vm.eventTitle = $$v
                                          },
                                          expression: "eventTitle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("pb-date-time-range-field", {
                                        ref: "eventDate",
                                        attrs: {
                                          hideDetails: "auto",
                                          disabled:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          readonly:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          label: _vm.$t("eventDateLabel") + "*",
                                          start: _vm.eventDate,
                                          end: _vm.endDate,
                                          rules: _vm.dateRules,
                                          required: "true",
                                          min: _vm.minDate,
                                          max: _vm.maxDate,
                                        },
                                        on: {
                                          "update:start": function ($event) {
                                            _vm.eventDate = $event
                                          },
                                          "update:end": function ($event) {
                                            _vm.endDate = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("pb-date-time-range-field", {
                                        ref: "eventTime",
                                        attrs: {
                                          hideDetails: "auto",
                                          disabled:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          readonly:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          label: _vm.$t("eventTimeLabel"),
                                          type: "time",
                                          start: _vm.eventStartTime,
                                          end: _vm.eventEndTime,
                                          rules: _vm.timeRules,
                                        },
                                        on: {
                                          "update:start": function ($event) {
                                            _vm.eventStartTime = $event
                                          },
                                          "update:end": function ($event) {
                                            _vm.eventEndTime = $event
                                          },
                                          blur: _vm.updateNotifications,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.showRepeats
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "hide-details": "auto",
                                              outlined: "",
                                              items: _vm.occurrences,
                                              readonly:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                              disabled:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                            },
                                            model: {
                                              value: _vm.repeats,
                                              callback: function ($$v) {
                                                _vm.repeats = $$v
                                              },
                                              expression: "repeats",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showSpecialDay
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "hide-details": "auto",
                                              outlined: "",
                                              items: _vm.specialDayItems,
                                              readonly:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                              disabled:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                            },
                                            model: {
                                              value: _vm.specialDayId,
                                              callback: function ($$v) {
                                                _vm.specialDayId = $$v
                                              },
                                              expression: "specialDayId",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { staticClass: "event-checkboxes mx-auto" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                _vm.$t("privateEventLabel"),
                                              readonly:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                              disabled:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted,
                                            },
                                            model: {
                                              value: _vm.privateFlag,
                                              callback: function ($$v) {
                                                _vm.privateFlag = $$v
                                              },
                                              expression: "privateFlag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.isCycleSchedule
                                        ? _c(
                                            "v-col",
                                            [
                                              _vm.showNoCycle
                                                ? _c("v-checkbox", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t(
                                                          "noCycleDayLabel"
                                                        ),
                                                      readonly:
                                                        !_vm.isEditable ||
                                                        _vm.isFieldRestricted ||
                                                        _vm.noSchoolNoCycleDisabled,
                                                      disabled:
                                                        !_vm.isEditable ||
                                                        _vm.isFieldRestricted ||
                                                        _vm.noSchoolNoCycleDisabled,
                                                    },
                                                    model: {
                                                      value: _vm.noCycle,
                                                      callback: function ($$v) {
                                                        _vm.noCycle = $$v
                                                      },
                                                      expression: "noCycle",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label: _vm.$t("noSchoolDayLabel"),
                                              readonly:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted ||
                                                _vm.noSchoolNoCycleDisabled,
                                              disabled:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted ||
                                                _vm.noSchoolNoCycleDisabled,
                                            },
                                            model: {
                                              value: _vm.noSchool,
                                              callback: function ($$v) {
                                                _vm.noSchool = $$v
                                              },
                                              expression: "noSchool",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        _vm._l(
                                          _vm.eventNotificationsItems,
                                          function (item, i) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: i,
                                                staticClass: "mr-2",
                                                attrs: { close: "" },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeNotifcation(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { start: "" },
                                                  },
                                                  [_vm._v("fal fa-bell")]
                                                ),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      autofocus: "",
                                      "hide-details": "auto",
                                      label: _vm.$t("titleLabel"),
                                      outlined: "",
                                      readonly: !_vm.isEditable,
                                      disabled: !_vm.isEditable,
                                    },
                                    model: {
                                      value: _vm.eventTitle,
                                      callback: function ($$v) {
                                        _vm.eventTitle = $$v
                                      },
                                      expression: "eventTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("pb-date-time-range-field", {
                                    ref: "eventDate",
                                    attrs: {
                                      hideDetails: "auto",
                                      disabled:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                      readonly:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                      label: _vm.$t("eventDateLabel") + "*",
                                      start: _vm.eventDate,
                                      end: _vm.endDate,
                                      rules: _vm.dateRules,
                                      required: "true",
                                      min: _vm.minDate,
                                      max: _vm.maxDate,
                                    },
                                    on: {
                                      "update:start": function ($event) {
                                        _vm.eventDate = $event
                                      },
                                      "update:end": function ($event) {
                                        _vm.endDate = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("pb-date-time-range-field", {
                                    ref: "eventTime",
                                    attrs: {
                                      hideDetails: "auto",
                                      disabled:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                      readonly:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                      label: _vm.$t("eventTimeLabel"),
                                      type: "time",
                                      start: _vm.eventStartTime,
                                      end: _vm.eventEndTime,
                                      rules: _vm.timeRules,
                                    },
                                    on: {
                                      "update:start": function ($event) {
                                        _vm.eventStartTime = $event
                                      },
                                      "update:end": function ($event) {
                                        _vm.eventEndTime = $event
                                      },
                                      blur: _vm.updateNotifications,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _vm.showRepeats
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "auto",
                                          outlined: "",
                                          items: _vm.occurrences,
                                          readonly:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          disabled:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                        },
                                        model: {
                                          value: _vm.repeats,
                                          callback: function ($$v) {
                                            _vm.repeats = $$v
                                          },
                                          expression: "repeats",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showSpecialDay
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "auto",
                                          outlined: "",
                                          items: _vm.specialDayItems,
                                          readonly:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                          disabled:
                                            !_vm.isEditable ||
                                            _vm.isFieldRestricted,
                                        },
                                        model: {
                                          value: _vm.specialDayId,
                                          callback: function ($$v) {
                                            _vm.specialDayId = $$v
                                          },
                                          expression: "specialDayId",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "event-checkboxes" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "hide-details": "auto",
                                      label: _vm.$t("privateEventLabel"),
                                      readonly:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                      disabled:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted,
                                    },
                                    model: {
                                      value: _vm.privateFlag,
                                      callback: function ($$v) {
                                        _vm.privateFlag = $$v
                                      },
                                      expression: "privateFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isCycleSchedule
                                ? _c(
                                    "v-col",
                                    [
                                      _vm.showNoCycle
                                        ? _c("v-checkbox", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label: _vm.$t("noCycleDayLabel"),
                                              readonly:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted ||
                                                _vm.noSchoolNoCycleDisabled,
                                              disabled:
                                                !_vm.isEditable ||
                                                _vm.isFieldRestricted ||
                                                _vm.noSchoolNoCycleDisabled,
                                            },
                                            model: {
                                              value: _vm.noCycle,
                                              callback: function ($$v) {
                                                _vm.noCycle = $$v
                                              },
                                              expression: "noCycle",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "hide-details": "auto",
                                      label: _vm.$t("noSchoolDayLabel"),
                                      readonly:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted ||
                                        _vm.noSchoolNoCycleDisabled,
                                      disabled:
                                        !_vm.isEditable ||
                                        _vm.isFieldRestricted ||
                                        _vm.noSchoolNoCycleDisabled,
                                    },
                                    model: {
                                      value: _vm.noSchool,
                                      callback: function ($$v) {
                                        _vm.noSchool = $$v
                                      },
                                      expression: "noSchool",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.haveNotificationsItems
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    _vm._l(
                                      _vm.eventNotificationsItems,
                                      function (item, i) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: i,
                                            staticClass: "mr-2",
                                            attrs: { close: "" },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.removeNotifcation(i)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { start: "" },
                                              },
                                              [_vm._v("fal fa-bell")]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "div",
                {
                  key: _vm.localRefreshKey,
                  on: {
                    mouseenter: function ($event) {
                      _vm.isSectionsHovered = true
                    },
                    mouseleave: function ($event) {
                      _vm.isSectionsHovered = false
                    },
                  },
                },
                [
                  _c("text-editor-full-screen-button", {
                    attrs: {
                      isSectionsHovered: _vm.isSectionsHovered,
                      isTextFieldsVisible: _vm.isTextFieldsVisible,
                    },
                    on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
                  }),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "editor-section-tabs",
                      attrs: {
                        "center-active": "",
                        centered: "",
                        "show-arrows": "",
                        "prev-icon": "fal fa-chevron-left",
                        "next-icon": "fal fa-chevron-right",
                      },
                      model: {
                        value: _vm.localActiveTab,
                        callback: function ($$v) {
                          _vm.localActiveTab = $$v
                        },
                        expression: "localActiveTab",
                      },
                    },
                    _vm._l(_vm.filteredTabs, function (section) {
                      return _c("v-tab", { key: section.section }, [
                        _vm._v(_vm._s(section.label)),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.localActiveTab,
                        callback: function ($$v) {
                          _vm.localActiveTab = $$v
                        },
                        expression: "localActiveTab",
                      },
                    },
                    [
                      _vm._l(_vm.filteredTabs, function (section, index) {
                        return [
                          section.section === "description"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          refreshKey: _vm.localRefreshKey,
                                          config: _vm.localTextEditorConfig,
                                        },
                                        model: {
                                          value: _vm.eventText,
                                          callback: function ($$v) {
                                            _vm.eventText = $$v
                                          },
                                          expression: "eventText",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "attachments"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable && _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.hasAttachments
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearAttachments,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myFiles"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fal fa-paperclip"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addGoogleDriveLabel"
                                                          ),
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addGoogleDriveAttachments,
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fab fa-google-drive"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.attachmentItems,
                                              showShortValue: false,
                                              clickable: true,
                                            },
                                            on: {
                                              otherAction:
                                                _vm.toggleAttachmentPrivateFlag,
                                              deleteItem: _vm.deleteAttachment,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "value",
                                                  fn: function ({
                                                    value,
                                                    data,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "other-actions",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "section-editor-checkbox",
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "privateLabel"
                                                            ),
                                                          "input-value":
                                                            item.data
                                                              .privateFlag,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleAttachmentPrivateFlag(
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localActiveTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.attachmentItems,
                                          showShortValue: false,
                                          clickable: true,
                                          showDelete: false,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function ({ value, data }) {
                                                return [
                                                  !data.privateFlag
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "addDays"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable && _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                            clearable:
                                              _vm.hasAddDays &&
                                              !_vm.isFieldRestricted,
                                          },
                                          on: {
                                            add: _vm.addAddDays,
                                            clear: _vm.clearAddDays,
                                          },
                                        },
                                        _vm._l(
                                          _vm.addDaysHolder,
                                          function (day, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: "ad" + i,
                                                staticClass:
                                                  "event-editor-day ma-2",
                                              },
                                              [
                                                _c("pb-date-field", {
                                                  attrs: {
                                                    prependInnerIcon: false,
                                                    dense: "",
                                                    readonly:
                                                      _vm.isFieldRestricted &&
                                                      !day.new,
                                                    appendOuterIcon:
                                                      _vm.isAddOrRemoveDayRestricted(
                                                        day.value
                                                      )
                                                        ? false
                                                        : "fal fa-trash-alt",
                                                    appendOuterLabel:
                                                      _vm.isAddOrRemoveDayRestricted(
                                                        day.value
                                                      )
                                                        ? ""
                                                        : _vm.$t(
                                                            "removeDayLabel"
                                                          ),
                                                    min: _vm.minDate,
                                                    max: _vm.maxDate,
                                                    allowedDates:
                                                      _vm.addDayAllowedDates,
                                                  },
                                                  on: {
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.deleteAddDays(
                                                          i
                                                        )
                                                      },
                                                  },
                                                  model: {
                                                    value: day.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        day,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "day.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm.localActiveTab === index
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.addDaysHolder,
                                          function (day, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: "ad" + i,
                                                staticClass:
                                                  "event-editor-day ma-2",
                                              },
                                              [
                                                _c("pb-date-field", {
                                                  attrs: {
                                                    prependInnerIcon: false,
                                                    dense: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: day.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        day,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "day.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "removeDays" &&
                          _vm.isEventDateRange
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable && _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                            clearable: _vm.hasRemoveDays,
                                          },
                                          on: {
                                            add: _vm.addRemoveDays,
                                            clear: _vm.clearRemoveDays,
                                          },
                                        },
                                        _vm._l(
                                          _vm.removeDaysHolder,
                                          function (day, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: "rd" + i,
                                                staticClass:
                                                  "event-editor-day ma-2 mr-4",
                                              },
                                              [
                                                _c("pb-date-field", {
                                                  attrs: {
                                                    prependInnerIcon: false,
                                                    dense: "",
                                                    readonly:
                                                      _vm.isFieldRestricted &&
                                                      !day.new,
                                                    appendOuterIcon:
                                                      _vm.isAddOrRemoveDayRestricted(
                                                        day.value
                                                      )
                                                        ? false
                                                        : "fal fa-trash-alt",
                                                    appendOuterLabel:
                                                      _vm.isAddOrRemoveDayRestricted(
                                                        day.value
                                                      )
                                                        ? ""
                                                        : _vm.$t(
                                                            "removeDayLabel"
                                                          ),
                                                    min: _vm.minDate,
                                                    max: _vm.maxDate,
                                                    allowedDates:
                                                      _vm.removeDayAllowedDates,
                                                  },
                                                  on: {
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.deleteRemoveDays(
                                                          i
                                                        )
                                                      },
                                                  },
                                                  model: {
                                                    value: day.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        day,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "day.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm.localActiveTab === index
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.removeDaysHolder,
                                          function (day, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: "ad" + i,
                                                staticClass:
                                                  "event-editor-day ma-2",
                                              },
                                              [
                                                _c("pb-date-field", {
                                                  attrs: {
                                                    prependInnerIcon: false,
                                                    dense: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: day.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        day,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "day.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("pb-confirm", { ref: "confirm" }),
              _c("my-files-picker", {
                ref: "filesPicker",
                attrs: { attachments: _vm.attachments },
                on: { "update:attachments": _vm.updateAttachments },
                model: {
                  value: _vm.dialogs.myFiles,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialogs, "myFiles", $$v)
                  },
                  expression: "dialogs.myFiles",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("stickers-picker", {
        attrs: { selectMultiple: false, stickers: _vm.stickers },
        on: {
          "update:stickers": function ($event) {
            _vm.stickers = $event
          },
          stickerPickerClosed: _vm.onStickerEditorClosed,
        },
        model: {
          value: _vm.isStickersPickerOpen,
          callback: function ($$v) {
            _vm.isStickersPickerOpen = $$v
          },
          expression: "isStickersPickerOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }