
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { namespace } from 'vuex-class';
import DisplayFormatOptions from '../core/DisplayFormatOptions.vue';
import { fonts } from '@/constants';
const stickers = namespace('stickers');
@Component({
  components: {
    draggable,
    DisplayFormatOptions
  }
})
export default class EditorSectionList extends Vue {
  @Prop({
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  items!: any

  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  showDelete!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  showShortValue!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  showStickerPreview!: boolean;

  @Prop({ required: true, type: Boolean, default: false })
  draggableDisabled!: boolean;

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {};
    }
  })
  rowStyle!: any;

  @Prop({ required: false, type: String, default: '' })
  rowClass!: any;

  @stickers.Getter('getSelectedStickers')
  selectedStickers!: any;

  selectedStickersItems: any[] = this.items;
  editingStickerId = 0;

  $refs!: {
    stickerFormat: Array<DisplayFormatOptions>
  }

  @Watch('items')
  onItemsChange(v:any) {
    this.selectedStickersItems = v;
    for (const sticker of v) {
      if (sticker.stickerDisplaySettings) {
        sticker.stickerDescriptionBold = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionBold;
        sticker.stickerDescriptionFont = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionFont;
        sticker.stickerDescriptionFontSize = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionFontSize;
        sticker.stickerDescriptionColor = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionColor;
        sticker.stickerDescriptionItalic = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionItalic;
        sticker.stickerDescriptionUnderline = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionUnderline;
        sticker.stickerSize = JSON.parse(sticker.stickerDisplaySettings).stickerSize;
        sticker.stickerBackgroundColor = JSON.parse(sticker.stickerDisplaySettings).stickerBackgroundColor;
      } else {
        if (this.isStickerDisplaySettingsUndefined(sticker)) {
          sticker.stickerDescriptionBold = 'N';
          sticker.stickerDescriptionFont = '1';
          sticker.stickerDescriptionFontSize = '10';
          sticker.stickerDescriptionColor = '#000000';
          sticker.stickerDescriptionItalic = 'N';
          sticker.stickerDescriptionUnderline = 'N';
          sticker.stickerSize = '40';
          sticker.stickerBackgroundColor = '#BDBDBD';
        }
      }
      // this.editingStickerId = ld.cloneDeep(this.stickerStyleId(sticker));
      if (!this.draggableDisabled) {
        this.addStyles();
      }
    }
  }

  get stickerDescriptionBold(): any {
    let stickerDescriptionBold = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionBold === undefined) {
          sticker.stickerDescriptionBold = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionBold;
        }
        stickerDescriptionBold = sticker.stickerDescriptionBold;
      }
    }
    if (stickerDescriptionBold === '') {
      this.editingStickerId = 0
    }
    return stickerDescriptionBold;
  }

  set stickerDescriptionBold(val: any) {
    this.selectedStickers.forEach((sticker:any) => {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionBold = val;
      }
    });
  }

  get stickerDescriptionFont(): any {
    let stickerDescriptionFont = '';
    let ctr = 0;
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionFont === undefined) {
          sticker.stickerDescriptionFont = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionFont;
          const payload = {
            newValue: sticker,
            index: ctr
          }
          this.$store.commit('stickers/updateSelectedStickers', payload);
        }
        stickerDescriptionFont = sticker.stickerDescriptionFont;
      }
      ctr++;
    }
    if (stickerDescriptionFont === '') {
      this.editingStickerId = 0
    }
    return stickerDescriptionFont;
  }

  set stickerDescriptionFont(val: any) {
    let indx;
    let tempValue;
    this.selectedStickers.forEach((sticker:any, index:number) => {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionFont = val;
        indx = index;
        tempValue = sticker;
      }
    });
    const payload = {
      newValue: tempValue,
      index: indx
    }
    this.$store.commit('stickers/updateSelectedStickers', payload);
  }

  get stickerDescriptionFontSize(): any {
    let stickerDescriptionFontSize = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionFontSize === undefined) {
          sticker.stickerDescriptionFontSize = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionFontSize;
        }
        stickerDescriptionFontSize = sticker.stickerDescriptionFontSize;
      }
    }
    if (stickerDescriptionFontSize === '') {
      this.editingStickerId = 0
    }
    return stickerDescriptionFontSize;
  }

  set stickerDescriptionFontSize(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionFontSize = val;
      }
    }
  }

  get stickerDescriptionColor(): any {
    let stickerDescriptionColor = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionColor === undefined) {
          sticker.stickerDescriptionColor = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionColor;
        }
        stickerDescriptionColor = sticker.stickerDescriptionColor;
      }
    }
    if (stickerDescriptionColor === '') {
      this.editingStickerId = 0
    }
    return stickerDescriptionColor;
  }

  set stickerDescriptionColor(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionColor = val;
      }
    }
  }

  get stickerDescriptionItalic(): any {
    let stickerDescriptionItalic = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionItalic === undefined) {
          sticker.stickerDescriptionItalic = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionItalic;
        }
        stickerDescriptionItalic = sticker.stickerDescriptionItalic;
      }
    }
    if (stickerDescriptionItalic === '') {
      this.editingStickerId = 0;
    }
    return stickerDescriptionItalic;
  }

  set stickerDescriptionItalic(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionItalic = val;
      }
    }
  }

  get stickerDescriptionUnderline(): any {
    let stickerDescriptionUnderline = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerDescriptionUnderline === undefined) {
          sticker.stickerDescriptionUnderline = JSON.parse(sticker.stickerDisplaySettings).stickerDescriptionUnderline;
        }
        stickerDescriptionUnderline = sticker.stickerDescriptionUnderline;
      }
    }
    if (stickerDescriptionUnderline === '') {
      this.editingStickerId = 0
    }
    return stickerDescriptionUnderline;
  }

  set stickerDescriptionUnderline(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerDescriptionUnderline = val;
      }
    }
  }

  get stickerSize(): any {
    let stickerSize = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerSize === undefined) {
          sticker.stickerSize = JSON.parse(sticker.stickerDisplaySettings).stickerSize;
        }
        stickerSize = sticker.stickerSize;
      }
    }
    if (stickerSize === '') {
      this.editingStickerId = 0
    }
    return stickerSize;
  }

  set stickerSize(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerSize = val;
      }
    }
  }

  get stickerBackgroundColor(): any {
    let stickerBackgroundColor = '';
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        if (sticker.stickerBackgroundColor === undefined) {
          sticker.stickerBackgroundColor = JSON.parse(sticker.stickerDisplaySettings).stickerBackgroundColor;
        }
        stickerBackgroundColor = sticker.stickerBackgroundColor;
      }
    }
    if (stickerBackgroundColor === '') {
      this.editingStickerId = 0
    }
    return stickerBackgroundColor;
  }

  set stickerBackgroundColor(val: any) {
    for (const sticker of this.selectedStickers) {
      const stickerId = sticker.stickerId || sticker.id;
      if (stickerId === this.editingStickerId) {
        sticker.stickerBackgroundColor = val;
      }
    }
  }

  get stickerFormat() {
    return {
      stickerDescriptionFont: this.stickerDescriptionFont,
      stickerDescriptionFontSize: this.stickerDescriptionFontSize,
      stickerDescriptionColor: this.stickerDescriptionColor,
      stickerDescriptionBold: this.stickerDescriptionBold,
      stickerDescriptionItalic: this.stickerDescriptionItalic,
      stickerDescriptionUnderline: this.stickerDescriptionUnderline,
      stickerSize: this.stickerSize,
      stickerBackgroundColor: this.stickerBackgroundColor
    }
  }

  set stickerFormat(val: any) {
    this.stickerDescriptionBold = val.stickerDescriptionBold;
    this.stickerDescriptionFont = val.stickerDescriptionFont;
    this.stickerDescriptionFontSize = val.stickerDescriptionFontSize;
    this.stickerDescriptionColor = val.stickerDescriptionColor;
    this.stickerDescriptionItalic = val.stickerDescriptionItalic;
    this.stickerDescriptionUnderline = val.stickerDescriptionUnderline;
    this.stickerSize = val.stickerSize.toString();
    this.stickerBackgroundColor = val.stickerBackgroundColor;
    this.addStyles();
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get localTableItems ():any {
    return this.selectedStickersItems
  }

  set localTableItems(val:any) {
    this.selectedStickersItems = val;
  }

  stickerStyleId(item:any) {
    if (!item.stickerId) {
      return item.id;
    } else {
      return item.stickerId;
    }
  }

  addStyles() {
    const styleContainer = document.getElementById('sticker-container') as HTMLElement;
    if (this.editingStickerId !== 0) {
      const style = document.createElement('style');
      style.innerHTML = `
        .sticker-img${this.editingStickerId} {
              filter: drop-shadow(0.2rem 0 0 ${this.stickerBackgroundColor})
                      drop-shadow(0 0.2rem 0 ${this.stickerBackgroundColor})
                      drop-shadow(-0.2rem 0 0 ${this.stickerBackgroundColor})
                      drop-shadow(0 -0.2rem 0 ${this.stickerBackgroundColor})
                      brightness(1.1);
        }
        
        .sticker-description${this.editingStickerId} .theme--light.v-input input {
          font-family: ${fonts[this.stickerDescriptionFont || '1'].text} !important;
          color: ${this.stickerDescriptionColor} !important;
          font-size: ${this.stickerDescriptionFontSize}pt !important;
          text-decoration: ${this.stickerDescriptionUnderline === 'Y' ? this.$t('fontStyleUnderline') : 'none'} !important;
          font-weight: ${this.stickerDescriptionBold === 'Y' ? this.$t('fontStyleBold') : 'normal'} !important;
          font-style: ${this.stickerDescriptionItalic === 'Y' ? this.$t('fontStyleItalic') : 'normal'} !important;
        }
      `;
      styleContainer.append(style);
    }
  }

  getStickerURL(item:any) {
    const stickerName = item.value ? item.value : item.url;
    return 'https://cdn.planbook.com/images/planbook-stickers/' + stickerName + '.png';
  }

  private dragDrop(evt: any) {
    this.$emit('dragDrop', evt, this.localTableItems);
  }

  showStickerFormatOption(event:any, stickerData:any) {
    const that = this;
    Promise.resolve().then(() => {
      this.editingStickerId = stickerData.stickerId || stickerData.id;
      let ref:any;
      for (const stickerRef of that.$refs.stickerFormat) {
        if (stickerRef.$props.stickerId === that.editingStickerId) {
          ref = stickerRef;
        }
      }
      return ref.show(event, true);
    });
  }

  isStickerDisplaySettingsUndefined(sticker:any) {
    if (sticker.stickerDescriptionBold === undefined &&
    sticker.stickerDescriptionFont === undefined &&
    sticker.stickerDescriptionFontSize === undefined &&
    sticker.stickerDescriptionColor === undefined &&
    sticker.stickerDescriptionItalic === undefined &&
    sticker.stickerDescriptionUnderline === undefined &&
    sticker.stickerSize === undefined &&
    sticker.stickerBackgroundColor === undefined) {
      return true;
    } else {
      return false;
    }
  }
}
