























































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VForm } from 'vuetify/lib';
import { stickerContext } from '../../constants';
import ld from 'lodash';
import StickerEditorSectionList from '../common/StickerEditorSectionList.vue';
import CommonUtils from '@/utils/common-utils';
const stickers = namespace('stickers');
const dayactions = namespace('dayactions');
const settings = namespace('settings');
@Component({
  components: {
    StickerEditorSectionList
  }
})
export default class StickersPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true, required: false })
  selectMultiple!: boolean;

  localRules = [(v: any) => !!v || 'Required'];
  elementPerRow = 5;
  activeButton = 'holiday';
  searchText = '';
  localStickersSelectedTab = 0;
  localSelected:any[] = [{ id: 0 }];

  @dayactions.State
  day!: any;

  @stickers.State
  availableStickerData!: any;

  @stickers.Getter('getSelectedStickers')
  selectedStickers!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  $refs!: {
    stickersEditorForm: InstanceType<typeof VForm>;
  };

  @Watch('value')
  onValueChange() {
    this.allStickers;
    this.localStickersSelectedTab = 0;
    this.localSelected = this.selectedStickers;
    if (!this.selectMultiple && CommonUtils.isEmpty(this.selectedStickers)) {
      this.localSelected = [{ id: 0 }];
    }
  }

  @Watch('searchText')
  searchOnChange() {
    this.allStickers;
  }

  get localSelectedNotEmpty() {
    return this.selectMultiple ? CommonUtils.isNotEmpty(this.localSelected) : this.localSelected[0].id !== 0;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get isEmpty() {
    return CommonUtils.isEmpty;
  }

  get date() {
    return this.day.date;
  }

  get allStickers() {
    const that = this;
    if (this.availableStickerData == null) {
      return [];
    }

    const stickers: any[] = this.availableStickerData.stickers;

    if (this.localStickersSelectedTab === 2) {
      const temp:any[] = stickers.filter((sticker: any) => (that.searchText === null && sticker.context === stickerContext.holidayContext) || (sticker.context === stickerContext.holidayContext && sticker.shortValue.toLowerCase().includes(that.searchText.toLowerCase())));
      return temp;
    } else if (this.localStickersSelectedTab === 1) {
      const temp:any[] = stickers.filter((sticker: any) => (that.searchText === null && sticker.context === stickerContext.remindersContext) || (sticker.context === stickerContext.remindersContext && sticker.shortValue.toLowerCase().includes(that.searchText.toLowerCase())));
      return temp;
    } else if (this.localStickersSelectedTab === 0) {
      const temp:any[] = stickers.filter((sticker: any) => (that.searchText === null && sticker.context === stickerContext.schoolEventsContext) || (sticker.context === stickerContext.schoolEventsContext && sticker.shortValue.toLowerCase().includes(that.searchText.toLowerCase())));
      return temp;
    }
  }

  get cardHeight() {
    return this.browserHeight - 400;
  }

  get updatedFields(): Set<string> {
    return this.$store.state.events.updatedFields;
  }

  set updatedFields(val: Set<string>) {
    this.$store.commit('events/setUpdatedFields', val);
  }

  widthOfRow(chunk : any[]) {
    return (100 * chunk.length / this.elementPerRow) + '%';
  }

  doApply() {
    this.updatedFields.add('STICKERS');
    this.$emit('update:stickers', this.localSelected);
    this.localValue = false;
    this.localSelected = [];
    if (!this.selectMultiple) {
      this.localSelected = [{ id: 0 }];
    }
  }

  // onItemTapped(item: any) {
  //   this.localSelected = item.id;
  // }

  onItemTapped(item: any) {
    if (!this.selectMultiple) {
      this.localSelected = [item];
    } else {
      const tempSelected = ld.cloneDeep(this.localSelected);
      if (!this.isSelected(item)) {
        tempSelected.push(item);
        this.localSelected = tempSelected;
      } else {
        const stickerId = item.stickerId || item.id;
        this.localSelected = this.localSelected.filter((s: any) => {
          const sId = s.stickerId || s.id;
          if (sId !== stickerId) {
            return true;
          }
          return false;
        });
      }
    }
  }

  isSelected(item: any) {
    const stickerId = item.stickerId || item.id;
    if (!this.selectMultiple) {
      return ld.cloneDeep(stickerId);
    }
    return this.localSelected.some((s:any) => {
      const sId = s.stickerId || s.id;
      if (sId === stickerId) {
        return true;
      }
      return false;
    });
  }

  sortSticker(item:any) {
    return item.sort((a:any, b:any) => {
      return a.shortValue.toLowerCase().localeCompare(b.shortValue.toLowerCase());
    });
  }

  async doOnClose() {
    this.$emit('stickerPickerClosed');
    return Promise.resolve(false);
  }
}
