var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function(ref) {
                  var onMenu = ref.on
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          attrs: {
                            label: _vm.$t("actionsLabel"),
                            icon: "",
                            right: ""
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "escape",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        Object.assign({}, onMenu)
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    )
                  ]
                }
              }
            : _vm.type == "custom"
            ? {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "div",
                      _vm._g(
                        _vm._b(
                          {
                            ref: "actionsButton",
                            attrs: {
                              tabindex: "0",
                              role: "button",
                              "aria-label": _vm.$t("actionsLabel")
                            },
                            on: {
                              focus: function($event) {
                                return _vm.$emit("focus")
                              },
                              blur: function($event) {
                                return _vm.$emit("blur")
                              },
                              keydown: [
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.showMenu = true
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              ],
                              keyup: [
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "left",
                                      37,
                                      $event.key,
                                      ["Left", "ArrowLeft"]
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    "button" in $event &&
                                    $event.button !== 0
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    "button" in $event &&
                                    $event.button !== 2
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "escape",
                                      undefined,
                                      $event.key,
                                      undefined
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    "button" in $event &&
                                    $event.button !== 2
                                  ) {
                                    return null
                                  }
                                  return _vm.focusExtraOptions.apply(
                                    null,
                                    arguments
                                  )
                                }
                              ]
                            }
                          },
                          "div",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._t("customButton")],
                      2
                    )
                  ]
                }
              }
            : null
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm.eventId > 0
                ? _c(
                    "div",
                    {
                      staticClass: "text-center font-weight-bold mb-1 mx-1",
                      style: {
                        color: _vm.$vuetify.theme.currentTheme.info,
                        "font-size": "12px",
                        "max-width": "280px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.actionHeader))]
                  )
                : _vm._e(),
              _vm.eventId > 0
                ? _c("v-divider", { staticStyle: { margin: "10px 0px" } })
                : _vm._e(),
              _vm._l(_vm.filteredActions, function(item, i) {
                return [
                  item.id !== "notification"
                    ? _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "" },
                          on: {
                            click: function($event) {
                              return _vm.doAction(item, $event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                domProps: { textContent: _vm._s(item.icon) }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticStyle: { width: "130px" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t(item.label))
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-list-group",
                        {
                          key: i,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "appendIcon",
                                fn: function() {
                                  return [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fal fa-chevron-down")
                                    ])
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          domProps: {
                                            textContent: _vm._s(item.icon)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticStyle: { width: "130px" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(item.label)
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.notificationsExpanded,
                            callback: function($$v) {
                              _vm.notificationsExpanded = $$v
                            },
                            expression: "notificationsExpanded"
                          }
                        },
                        [
                          _c("v-divider"),
                          _vm._l(_vm.timeOptions, function(item, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: i,
                                ref: "timeOption" + i,
                                refInFor: true,
                                staticClass: "pl-4",
                                attrs: {
                                  tabindex: _vm.notificationsExpanded ? 0 : -1
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addNotification(item)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-icon"),
                                _c("v-list-item-title", {
                                  staticStyle: { width: "130px" },
                                  domProps: { textContent: _vm._s(item.title) }
                                })
                              ],
                              1
                            )
                          }),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "pl-4",
                              attrs: {
                                tabindex: _vm.notificationsExpanded ? 0 : -1
                              },
                              on: { click: _vm.openCustomNotificaiton }
                            },
                            [
                              _c("v-list-item-icon"),
                              _c(
                                "v-list-item-title",
                                { staticStyle: { width: "130px" } },
                                [_vm._v(_vm._s(_vm.$t("customLabel")) + "...")]
                              )
                            ],
                            1
                          ),
                          _c("v-divider")
                        ],
                        2
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("display-format-options", {
        ref: "format",
        attrs: {
          type: "absolute",
          withShowTitle: false,
          stickerId: _vm.stickerId
        },
        model: {
          value: _vm.eventFormat,
          callback: function($$v) {
            _vm.eventFormat = $$v
          },
          expression: "eventFormat"
        }
      }),
      _c("event-notification", {
        ref: "notification",
        attrs: { mode: _vm.input.mode, data: _vm.input.data },
        model: {
          value: _vm.eventFormat,
          callback: function($$v) {
            _vm.eventFormat = $$v
          },
          expression: "eventFormat"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }