import PlanbookDraggable from './planbook-draggable';
import ld from 'lodash';

export default class SectionCardDraggable extends PlanbookDraggable {
  data = null;
  constructor(element, options) {
    options = options || {};
    super(
      element,
      ld.merge(
        {
          appendTo: '.plans',
          helper: 'clone',
          scroll: true,
          handle: '.section-card-draggable-handle',
          cursorAt: { top: 0, left: 0 },
          cursor: 'move'
        },
        options.options || {}
      ),
      options.listeners || {}
    );
    this.data = options.data;
  }
}
