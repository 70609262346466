var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function() {
            return [
              _c(
                "draggable",
                {
                  staticClass: "grabbable",
                  attrs: { tag: "tbody", disabled: _vm.draggableDisabled },
                  on: { end: _vm.dragDrop },
                  model: {
                    value: _vm.localTableItems,
                    callback: function($$v) {
                      _vm.localTableItems = $$v
                    },
                    expression: "localTableItems"
                  }
                },
                _vm._l(_vm.selectedStickersItems, function(item, i) {
                  return _c(
                    "tr",
                    {
                      key: item.id,
                      staticClass: "editor-list-item",
                      class: _vm.rowClass,
                      style: _vm.rowStyle
                    },
                    [
                      !_vm.draggableDisabled
                        ? _c(
                            "td",
                            { staticClass: "px-2 pl-3" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "#b5bac1" } },
                                [_vm._v(" fas fa-exchange-alt fa-rotate-90 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showStickerPreview
                        ? _c(
                            "td",
                            {
                              attrs: { align: "center", width: "30%" },
                              on: {
                                click: function($event) {
                                  !_vm.disabled &&
                                    _vm.$emit("itemClick", item, i)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "sticker-container" } },
                                [
                                  _c("img", {
                                    class: !_vm.draggableDisabled
                                      ? "sticker-img" + _vm.stickerStyleId(item)
                                      : "",
                                    staticStyle: {
                                      height: "4rem",
                                      width: "auto",
                                      "margin-top": "5px"
                                    },
                                    attrs: {
                                      src: _vm.getStickerURL(item),
                                      "lazy-src": _vm.getStickerURL(item),
                                      role: "presentation"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.showShortValue
                        ? _c(
                            "td",
                            {
                              attrs: { width: "100%" },
                              on: {
                                click: function($event) {
                                  !_vm.disabled &&
                                    _vm.$emit("itemClick", item, i)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: !_vm.draggableDisabled
                                    ? "sticker-description" +
                                      _vm.stickerStyleId(item)
                                    : ""
                                },
                                [
                                  !_vm.draggableDisabled
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "stickerDescriptionLabel"
                                          ),
                                          "validate-on-blur": "",
                                          autofocus: "",
                                          maxlength: "225",
                                          "hide-details": "auto",
                                          outlined: ""
                                        },
                                        model: {
                                          value: item.description,
                                          callback: function($$v) {
                                            _vm.$set(item, "description", $$v)
                                          },
                                          expression: "item.description"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.shortValue) + " "
                                        )
                                      ])
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "td",
                        [
                          _vm._t("other-actions", null, null, {
                            item: item,
                            index: i,
                            disabled: _vm.disabled
                          })
                        ],
                        2
                      ),
                      !_vm.draggableDisabled
                        ? _c(
                            "td",
                            { staticClass: "px-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { icon: "", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showStickerFormatOption(
                                        $event,
                                        item
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("fal fa-pencil")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showDelete
                        ? _c(
                            "td",
                            [
                              _vm._t(
                                "actions",
                                function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                        attrs: { icon: "", "x-small": "" },
                                        on: {
                                          click: function($event) {
                                            !_vm.disabled &&
                                              _vm.$emit("deleteItem", item, i)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-trash-alt")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                null,
                                { item: item, index: i, disabled: _vm.disabled }
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("display-format-options", {
                        ref: "stickerFormat",
                        refInFor: true,
                        attrs: {
                          stickerId: item.stickerId ? item.stickerId : item.id,
                          type: "absolute",
                          withShowTitle: false
                        },
                        model: {
                          value: _vm.stickerFormat,
                          callback: function($$v) {
                            _vm.stickerFormat = $$v
                          },
                          expression: "stickerFormat"
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }